import React, { Component } from "react";
import autoBind from 'react-autobind';

class RulesText extends Component {

  constructor(props){
    super(props);
    this.state = {
      
    }
    autoBind(this);
  }

  render(){
    return (
      <>
        <p><strong>Virtual Fishing Leagues - Rules and Additional Terms of Service</strong></p>
        <p>To submit a fish for a league, the fisherman/fisherwoman must be located in one of the 48 contiguous states unless specified under species the specific rules. Depending on the league and species being targeting, the league may be split into regions in an effort to make it as fair as possible. The fisherman/fisherwoman must designate their location for the region and may not submit any fish caught outside that region. Each region will have a separate leaderboard and share of the global prize pool.</p>
        <p>Fishermen/Fisherwomen participating in a league must be streaming live on twitch.tv using the GO League overlays and chatbot at the time of the fish being caught in order for the fish to be eligble for scoring in the league. Only live streamed catches will be accepted, and they must follow the submission guidelines to be accepted.</p>
        <p><strong>IMPORTANT! - Fish Submission Guidelines:</strong></p>
        <ol>
          <li>The fish must be caught visibly on stream.</li>
          <li>The fish must be weighed on stream shortly after being caught. </li>
          <li>The fisherman/fisherwoman is using a modern digital scale. </li>
          <li>The scale must show a clear and visible zero prior the weighing the fish. (Tare with lip grippers or other devices already attached)</li>
          <li>The scale must show a clear and visible weight for the fish. Show scale locking on stream.</li>
          <li>There is no reasonable suspicion of fowl play or tampering as determined by the league admins.</li>
          <li>The fish is sumbitted with a clip by the fisherman/fisherwoman or a designated moderator within 24 hours.</li>
        </ol>
        <p>Fish must be a minimum weight of 1 lb. unless otherwise specified in species specific rules. While fishing for GO League, you may not keep fish of the target species on stringers, or in a livewell, etc.</p>
        <p>You may only fish with one line in the water at a time unless otherwise specified in species specific rules.</p>
        <p>If it is too dark to see the fish clearly being caught and weighed, then the fish will not be counted.</p>
        <p>Fisherman/Fisherwoman in the United States may not submit fish caught from payed trophy waters. Participants agree to cooperate in the validation of any catches including disclosing to admins the location the fish has been caught.</p>
        <p>Snagged or foul hooked (hook outside the mouth) fish will not be accepted. </p>
        <p>The fisherman/fisherwoman must always follow their local boating and game fishing laws and regulations.</p>
        <p>Tie breakers are first determined by the amount of fish in the bag, then the largest single fish, followed by date/time of largest fish caught.</p>
        <p>Co-stream - Two fishermen/fisherwomen may participate from one livestream. The catch must be featured in the stream with the angler who will be submitting the catch. They must also follow the submission guidelines.</p>
        <p>GO League admins reserve the right to disqualify any individual catches or participants on grounds for unsportsmanlike conduct, reasonable suspicion of cheating, or violation of terms of service.</p>
        <p><strong>Bass League Rules:</strong></p>
        <p>Largemouth, Smallmouth, and Spotted Bass only.</p>
        <p>The use of live bait is not permitted.</p>
        <p>Only one line in the water at a time.</p>
        <p>USA only, North and South Regions</p>
        <p>Elite and Open Leagues, top 2 move up/down each season.</p>
        <p><strong>Channel Catfish Rules:</strong></p>
        <p>Channel catfish only, remember to show fish on stream to verify species.</p>
        <p>Weight must be taken with lip grippers or a net. These tools must be included when zeroing the scale.</p>
        <p>Maximum of up to two lines per your local regulations.</p>
        <p>No jugs, limb lines, trot lines, etc.</p>
        <p>Any legal bait allowed per your local regulations.</p>
        <p>USA only, one region, open league</p>
        <p><strong>Carp Rules:</strong></p>
        <p>Common Carp, Grass Carp, or Mirror Carp, remember to show fish on stream to verify species.</p>
        <p>Fish can be weighed in a net or other device designed to hold fish while weighing. This device must be zero'd with the scale visibly on stream.</p>
        <p>Maximum of up to three lines per your local regulations.</p>
        <p>Any legal bait allowed per your local regulations.</p>
        <p>Global league, one region, open league</p>
      </>
    );
  };
}

export default RulesText