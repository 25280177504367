import React from "react";
import { Link } from "react-router-dom";
import { signOut } from "../../actions/authActions";
import { connect } from "react-redux";
import $ from 'jquery';

const NavItems = ({ signOut, uid, profile, isHome, admin, beta, showNav, mod, stripeRole, subStatus }) => {
  const isLocal = () => {
    return (window.location.hostname === 'localhost');
  };
  const scrollTo = (element) => {
    $([document.documentElement, document.body]).animate({
      scrollTop: $("[data-scrollto="+element+"]").offset().top
    }, 1000);
  }
  const isAdmin = () => {
    if (admin && typeof admin.global !== 'undefined' && admin.global < 6) {
      return true;
    }
  };
  const isPremium = () => {
    if (stripeRole === 'premium') {
      return true;
    }
  };
  if (uid) {
    return (
      <>
        <ul className={"navbar-nav navbar-main ml-auto"+((showNav)&&' navbar-show')}>
          <li className="nav-item">
            <Link to="/rankings" className="nav-link">
              <span className="material-icons">web</span>
              <span className="nav-label">Rankings</span>
            </Link>
          </li>
          {
            (isAdmin()) &&
            <li className="nav-item">
              <Link to="/admin" className="nav-link">
                <span className="material-icons">shield</span>
                <span className="nav-label">Admin</span>
              </Link>
            </li>
          }
          {/* <li className="nav-item">
            <span>Hi, { profile.display_name }</span>
          </li> */}
          {
            (mod) &&
            <li className="nav-item">
              <Link to="/mod" className="nav-link">
                <span className="material-icons">colorize</span>
                <span className="nav-label">Mod</span>
              </Link>
            </li>
          }
          {
            (beta || (isPremium() && subStatus)) &&
            <li className="nav-item">
              <Link to="/catch" className="nav-link">
                <span className="material-icons">add</span>
                <span className="nav-label">Catch</span>
              </Link>
            </li>
          }
          <li className="nav-item">
            <Link to="/settings" className="nav-link">
              <span className="material-icons">settings</span>
              <span className="nav-label">Settings</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/signin" className="nav-link" onClick={signOut}>
              <span className="material-icons">exit_to_app</span>
              <span className="nav-label">Logout</span>
            </Link>
          </li>
        </ul>
      </>
    );
  } else {
    return (
      <>
        <ul className={"navbar-nav navbar-main ml-auto"+((showNav)&&' navbar-show')}>
          {
            (isHome) ? 
              <>
                <li className="nav-item">
                  <Link to="/rankings" className="nav-link">
                    <span className="nav-label">Rankings</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <a onClick={() => scrollTo('benefits')} className="nav-link">
                    Benefits
                  </a>
                </li>
                <li className="nav-item">
                  <a onClick={() => scrollTo('donors')} className="nav-link">
                    Patrons
                  </a>
                </li>
                <li className="nav-item">
                  <a onClick={() => scrollTo('sponsors')} className="nav-link">
                    Sponsors
                  </a>
                </li>
                <li className="nav-item">
                  <a onClick={() => scrollTo('pricing')} className="nav-link">
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a href={ (window.location.hostname === 'localhost') ? "http://localhost:5001/go-league/us-central1/redirect" : "https://us-central1-go-league.cloudfunctions.net/redirect" } className="nav-link nav-cta">
                    Get Started
                  </a>
                </li>
              </>
            :
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  &lsaquo; Back
                </Link>
              </li>
            }
        </ul>
      </>
    );
  }
};

const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid;
  return {
    uid: uid,
  };
};

const mapDistpatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDistpatchToProps)(NavItems);
