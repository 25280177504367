import React, { Component } from "react";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import autoBind from 'react-autobind';
import { submitFish, deleteFish, updateFish } from '../../actions/overlayActions';
import { buyProduct } from "../../actions/productActions";
import { objToArray } from '../../utils/Object';
import TimeAgo from 'react-timeago';
import Switch from "react-switch";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import Foot from "../layout/Foot";
import $ from 'jquery';

class Catches extends Component {

  constructor(props){
    super(props);
    this.state = {
      initialized : false,
      addingNew : false,
      selectedNew : false,
      toggleDelete : null,
      done : false,
      editing : null,
      form : {},
      inputs : {
        selectedSpecies : '',
        scaleOz : false,
        scaleKg : false,
        fishDateTime : new Date(),
        fishVerified : true,
        fishClip : '',
      }
    }
    autoBind(this);
  }

  componentDidUpdate(){
    
  }

  validateForm() {
    let inputs = this.state.inputs;
    let species = null;
    let addNew = false;
    if (inputs.selectedSpecies !== '' && inputs.selectedSpecies !== null) {
      species = inputs.selectedSpecies;
    } else if (inputs.newSpecies !== '' && inputs.newSpecies !== null && this.state.selectedNew === true) {
      addNew = true;
      species = inputs.newSpecies;
    }
    let weight = null;
    let weightLbs = (!isNaN(+inputs.fishWeight)) ? parseFloat((+inputs.fishWeight).toFixed(2)) : null ;
    let weightOz = null;
    if (inputs.scaleKg) {
      weight = (!isNaN(+inputs.fishWeight)) ? parseFloat(((+inputs.fishWeight)*2.20462).toFixed(2)) : null ;
    } else {
      if (inputs.scaleOz) {
        weightOz = (!isNaN(+inputs.fishWeightOz)) ? parseInt((+inputs.fishWeightOz).toFixed()) : 0 ;
        weightLbs = (!isNaN(+inputs.fishWeight)) ? parseInt((+inputs.fishWeight).toFixed()) : 0 ;
        if (weightOz > 0 || weightLbs > 0) {
          weight = parseFloat((weightLbs + (weightOz/16)).toFixed(2));
        }
      } else {
        if (weightLbs) {
          weight = weightLbs;
        }
      }
    }
    let length = (!isNaN(+inputs.fishLength)) ? (+inputs.fishLength).toFixed(2) : null;
    let dateTime = (Date.parse(inputs.fishDateTime) > 0) ? inputs.fishDateTime : null;
    let clip = (inputs.fishClip !== '' && typeof inputs.fishClip !== 'undefined'  && (inputs.fishClip.indexOf('https://clips.twitch.tv/') > -1 || ((inputs.fishClip.indexOf('https://twitch.tv/') > -1 || inputs.fishClip.indexOf('https://www.twitch.tv/') > -1) && inputs.fishClip.indexOf('/clip/') > -1))) ? inputs.fishClip : null;
    let verified = (inputs.fishVerified === false) ? false : (inputs.fishVerified === true && clip && weight && this.isInLeague()) ? true : false ;
    if (species && dateTime) {
      this.setState({
        done : true,
        form : {
          species : species,
          weight : weight,
          length : length,
          dateTime : dateTime,
          askVerify: verified,
          clip : clip,
          addNew : addNew
        }
      });
    } else {
      this.setState({
        done : false,
        form : {}
      });
    }
  }
  
  scrollTo(element) {
    $([document.documentElement, document.body]).animate({
      scrollTop: $("[data-scrollto="+element+"]").offset().top
    }, 1000);
  }

  isInLeague() {
    let leagueData = {};
      if (this.props.goleague && this.props.goleague.leagues && this.props.overlay && this.props.overlay.owner) {
      let owner = this.props.overlay.owner;
        let leagues = this.props.goleague.leagues;
        leagues.forEach(league => {
          if (league.starts.toDate() < new Date() && new Date() < league.ends.toDate()) {
            if (league.subleagues) {
              league.subleagues.forEach((subleague, index) => {
                if (subleague.participants && typeof subleague.participants[owner] !== 'undefined') {
                  leagueData = Object.assign({}, league);
                  leagueData.userSubleague = index;
                }
              });
            }
          }
        });
      }
      if (typeof leagueData.userSubleague === 'number') {
        let fishTypes = this.props.goleague.types[leagueData.type].species;
        if (fishTypes.includes(this.state.form.species) && this.state.form.weight >= leagueData.minWeight) {
          return true;
        }
      }
  }
  
  getLeague() {
    let leagueData = {};
    if (this.props.goleague && this.props.goleague.leagues && this.props.overlay && this.props.overlay.owner) {
    let owner = this.props.overlay.owner;
      let leagues = this.props.goleague.leagues;
      leagues.forEach(league => {
        if (league.starts.toDate() < new Date() && new Date() < league.ends.toDate()) {
          if (league.subleagues) {
            league.subleagues.forEach((subleague, index) => {
              if (subleague.participants && typeof subleague.participants[owner] !== 'undefined') {
                leagueData = Object.assign({}, league);
                leagueData.userSubleague = index;
              }
            });
          }
        }
      });
    }
    if (typeof leagueData.userSubleague === 'number') {
      let fishTypes = this.props.goleague.types[leagueData.type].species;
      if (fishTypes.includes(this.state.form.species) && this.state.form.weight >= leagueData.minWeight) {
        return leagueData;
      }
    }
  }

  submitFish() {
    let uid = (this.props.overlay.owner) ? this.props.overlay.owner : this.props.uid;
    if (this.state.done) {
      if (this.state.editing) {
        this.props.updateFish(this.state.editing, this.state.form, uid, (status) => {
          if (status.success) {
            this.scrollTo('catch-list');
            setTimeout(() => {
              this.setState({
                initialized : false,
                addingNew : false,
                selectedNew : false,
                done : false,
                editing : null,
                form : {},
                inputs : {
                  selectedSpecies : '',
                  scaleOz : false,
                  scaleKg : false,
                  fishDateTime : new Date(),
                  fishVerified : true,
                  fishClip : ''
                }
              });
            }, 1000);
          }
        });
      } else {
        this.props.submitFish(this.state.form, uid, (status) => {
          if (status.success) {
            this.scrollTo('catch-list');
            setTimeout(() => {
              this.setState({
                initialized : false,
                addingNew : false,
                selectedNew : false,
                done : false,
                editing : null,
                form : {},
                inputs : {
                  selectedSpecies : '',
                  scaleOz : false,
                  scaleKg : false,
                  fishDateTime : new Date(),
                  fishVerified : true,
                  fishClip : ''
                }
              });
            }, 1000);
          }
        });
      }
      
      //add new species if needed...
    }
  }

  cancelEdit() {
    this.setState({
      initialized : false,
      addingNew : false,
      selectedNew : false,
      done : false,
      editing : null,
      form : {},
      inputs : {
        selectedSpecies : '',
        scaleOz : false,
        scaleKg : false,
        fishDateTime : new Date(),
        fishVerified : true,
        fishClip : ''
      }
    });
  }

  editCatch(id) {
    this.scrollTo('catch-form');
    this.setState({
      editing : id,
      addingNew : (!this.props.species[this.props.catches[id].species]) ? true : false,
      selectedNew : (!this.props.species[this.props.catches[id].species]) ? true : false,
      inputs : {
        selectedSpecies : (this.props.species[this.props.catches[id].species]) ? this.props.catches[id].species : '',
        fishWeight : this.props.catches[id].weight,
        fishLength : (this.props.catches[id].length > 0) ? this.props.catches[id].length : 0,
        fishDateTime : this.props.catches[id].dateTime.toDate(),
        fishClip : this.props.catches[id].clip || '',
        fishVerified : (this.props.catches[id].askVerify) ? true : false,
        newSpecies : (!this.props.species[this.props.catches[id].species]) ? this.props.catches[id].species : ''
      }
    }, () => {
      this.validateForm();
    });
  }

  toggleDelete(id) {
    this.setState({
      toggleDelete : id
    });
  }

  cancelDelete() {
    this.setState({
      toggleDelete : null
    });
  }

  confirmDelete(id) {
    this.props.deleteFish(id, (status) => {
      if (status.success) {
        setTimeout(() => {
          this.setState({
            ...this.state,
            toggleDelete : null
          });
        }, 1000);
      } else {
        console.log(status);
      }
    });
  }

  selectSpecies(id) {
    this.setState({
      inputs: {
        ...this.state.inputs,
        selectedSpecies : id
      }
    }, () => {
      this.validateForm();
    });
  }

  resetSpecies(flag) {
    this.setState({
      done : false,
      addingNew : false,
      selectedNew : false,
      inputs: {
        ...this.state.inputs,
        selectedSpecies : null,
        newSpecies : null,
        fishDateTime : new Date()
      }
    });
  }

  selectNewSpecies() {
    if (this.newSpecies.value) {
      this.setState({
        ...this.state,
        selectedNew : true,
        inputs : {
          ...this.state.inputs,
        }
      }, () => {
        this.validateForm();
      });
    }
  }

  addSpecies() {
    this.setState({
      ...this.state,
      addingNew : true,
      inputs: {
        ...this.state.inputs,
        newSpecies : this.fishSpecies.value
      }
    }, () => {
      this.validateForm();
    });
  }

  handleDateTimeChange(e) {
    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        fishDateTime : e._d
      }
    }, () => {
      this.validateForm();
    });
  }

  handleChange(e) {
    let inputs = this.state.inputs;
    if (e.target) {
      inputs[e.target.name] = e.target.value
      this.setState({
        ...this.state,
        inputs: {
          ...this.state.inputs,
          ...inputs
        }
      }, () => {
        this.validateForm();
      });
    }
  }

  handleSwitchChange(e, field) {
    let inputs = {};
    inputs[field] = e;
    this.setState({
      ...this.state,
      inputs: {
        ...this.state.inputs,
        ...inputs
      }
    }, () => {
      this.validateForm();
    });
  }

  render(){
    const { uid, beta, stripeRole, species, catches, overlay, usernames, mod, mods, modFor, me, buyProduct } = this.props;
    const isPremium = () => {
      return (stripeRole === 'premium');
    };
    if (!(mod || beta || isPremium())) {
      return <Redirect to="/" />;
    }
    if (typeof modFor !== 'undefined' && typeof modFor.includes === 'function' && overlay && overlay.owner && !(modFor.includes(overlay.owner))) {
      return <Redirect to="/" />;
    }
    const getSubStatus = () => {
      let active = false;
      if (me.subs && me.subs.length > 0) {
        me.subs.forEach((sub) => {
          if (sub.status === 'active') {
            active = true;
          }
        });
      }
      if (active) {
        return true;
      }
      return false;
    };
    return (
      <>
        <section className="hero hero-sm">
          <div className="container">
            <h1>Catches</h1>
            <h2>Update { (overlay && overlay.owner && overlay.owner !== uid && usernames && usernames[overlay.owner] && usernames[overlay.owner].display_name) ? usernames[overlay.owner].display_name+'\'s' : 'Your' } Catch History</h2>
          </div>
          <div className="hero-water"></div>
        </section>
        <section>
          <div className="container">
          {
            ((getSubStatus() || beta || overlay.owner !== uid) && overlay.active === true) ?
              <>
              {
                (usernames && usernames[overlay.owner] && overlay.owner !== uid) &&
                <div className="alert-msg alert-info">
                  <span className="material-icons">colorize</span>
                  <span className="alert-msg-text">You have been granted mod access to edit this page by { usernames[overlay.owner].display_name }.</span>
                </div>
              }
              <div className="alert-msg alert-danger">
                <span className="material-icons">warning</span>
                <span className="alert-msg-text">The weight of the catch and a clip link is required for any catch to be considered for league verification. Please ensure you follow the <Link to='/rules'>Fish Submission Guidelines</Link> section of the rules and submit a clip that meets all of those requirements.</span>
              </div>
              <h4 data-scrollto="catch-form">{ (this.state.editing) ? 'Edit' : 'Add' } { (overlay && overlay.owner && overlay.owner !== uid && usernames && usernames[overlay.owner] && usernames[overlay.owner].display_name) ? usernames[overlay.owner].display_name+'\'s' : 'Your' } Catch</h4>
              <div className="settings-form species-form">
                <div className="col col-2">
                  {
                    (!this.state.addingNew) ?
                      (!this.state.inputs.selectedSpecies) ?
                        <div className="formline results-box">
                          <label htmlFor="fishSpecies">Fish Species: </label>
                          <input type="text" placeholder="Search Species..." ref={el => this.fishSpecies=el} value={this.state.inputs.fishSpecies} onChange={this.handleChange} name="fishSpecies" />
                          <div className="fish-results">
                            {
                              (this.state.inputs.fishSpecies && this.state.inputs.fishSpecies.length > 0) &&
                              <ul>
                                {
                                  (this.state.inputs.fishSpecies && this.state.inputs.fishSpecies.length > 0 && objToArray(species).filter(fish => fish.display_name.toLowerCase().indexOf(this.state.inputs.fishSpecies.toLowerCase()) > -1).length > 0) &&
                                  objToArray(species).filter(fish => fish.display_name.toLowerCase().indexOf(this.state.inputs.fishSpecies.toLowerCase()) > -1).map(fish => {
                                    return <li key={fish._id} onClick={ () => this.selectSpecies(fish._id) }><img src={fish.img} /><span>{ fish.display_name }</span></li>
                                  })
                                }
                                <li className="add-new" onClick={ this.addSpecies }><img src="/img/species-unknown.png" /><span>Add New Species...</span></li>
                              </ul>
                            }
                          </div>
                        </div>
                        :
                        <div className="formline">
                          {
                            (species[this.state.inputs.selectedSpecies]) &&
                            <div className="selected-species">
                              <img src={species[this.state.inputs.selectedSpecies].img} />
                              <span>{ species[this.state.inputs.selectedSpecies].display_name }</span>
                              <button onClick={ this.resetSpecies } className="btn btn-error"><span className="material-icons">clear</span></button>
                            </div>
                          }
                        </div>
                      :
                        (!this.state.selectedNew) ?
                          <>
                            <div className="formline">
                              <label htmlFor="newSpecies">Enter New Species: </label>
                              <input type="text" placeholder="Add Species..." ref={el => this.newSpecies=el} value={this.state.inputs.newSpecies} onChange={this.handleChange} name="newSpecies" />
                            </div>
                            <div className="formline">
                              <button onClick={ this.selectNewSpecies } className="btn btn-primary">Add New Species</button>
                            </div>
                          </>
                    :
                      <div className="formline">
                        <div className="selected-species">
                          <img src="/img/species-unknown.png" />
                          <span>{ this.state.inputs.newSpecies }</span>
                          <button onClick={ this.resetSpecies } className="btn btn-error"><span className="material-icons">clear</span></button>
                        </div>
                      </div>
                    }
                    {
                      (this.state.selectedNew || species[this.state.inputs.selectedSpecies]) &&
                      <>
                        <div className="formline">
                          <label htmlFor="scaleKg">Does the Scale use KG?</label>
                          <Switch onColor="#b1cc49" offColor="#231f20" onChange={ (e) => this.handleSwitchChange(e, 'scaleKg') } checked={this.state.inputs.scaleKg} />
                        </div>
                        {
                          !this.state.inputs.scaleKg && (
                            <div className="formline">
                              <label htmlFor="scaleOz">Does the Scale Use Ounces? </label>
                              <Switch onColor="#b1cc49" offColor="#231f20" onChange={ (e) => this.handleSwitchChange(e, 'scaleOz') } checked={this.state.inputs.scaleOz} />
                            </div>
                          )
                        }
                        <div className="weight-row">
                          <div className={ (this.state.inputs.scaleOz && !this.state.inputs.scaleKg) ? 'col col-2' : '' }>
                            <div className="formline">
                              <label htmlFor="fishWeight">Weight ({ (this.state.inputs.scaleKg) ? 'Kg' : 'Lb' }): </label>
                              <input type="number" min="0" step={ (this.state.inputs.scaleOz) ? '1' : '0.01' } ref={el => this.fishWeight=el} value={this.state.inputs.fishWeight} onChange={this.handleChange} name="fishWeight" />
                            </div>
                          </div>
                          <div className={ (this.state.inputs.scaleOz && !this.state.inputs.scaleKg) ? 'col col-2' : '' }>
                            {
                              (this.state.inputs.scaleOz && !this.state.inputs.scaleKg) &&
                              <div className="formline">
                                <label htmlFor="fishWeightOz">Weight (oz): </label>
                                <input type="number" min="0" max="15" step="1" placeholder="0" ref={el => this.fishWeightOz=el} value={this.state.inputs.fishWeightOz} onChange={this.handleChange} name="fishWeightOz" />
                              </div>
                            }
                          </div>
                        </div>
                        <div className="formline">
                          <label htmlFor="fishLength">Length in Inches (optional): </label>
                          <input type="number" min="0" ref={el => this.fishLength=el} value={this.state.inputs.fishLength} onChange={this.handleChange} name="fishLength" />
                        </div>
                        <div className="formline">
                          <label htmlFor="fishDateTime">When Was The Fish Caught?</label>
                          <Datetime onChange={ this.handleDateTimeChange } value={ this.state.inputs.fishDateTime } />
                        </div>
                        <div className="formline">
                          <label htmlFor="fishClip">Twitch Clip of Fish Weight: </label>
                          <input type="text" placeholder="Paste Twitch Clip Link..." ref={el => this.fishClip=el} value={this.state.inputs.fishClip} onChange={this.handleChange} name="fishClip" />
                        </div>
                        {
                          (this.state.done && this.state.form && typeof this.state.form.clip === 'string' && this.state.form.clip !== '' && this.state.form.weight > 0 && this.isInLeague()) &&
                          <div className="verified-catch">
                            <div className="formline">
                              <div className="verified-header">
                                <Switch onColor="#b1cc49" offColor="#231f20" onChange={ (e) => this.handleSwitchChange(e, 'fishVerified') } checked={this.state.inputs.fishVerified} />
                                <label className="verified-catch-label" htmlFor="fishVerified"><span>GO League Verification</span><span className="material-icons">verified</span></label>
                              </div>
                              <p>This catch qualifies for the current active { this.getLeague().subleagues[this.getLeague().userSubleague].display_name } { this.getLeague().display_name } league. When submitting the catch with GO League Verification on, the catch will be locked for review by admins to ensure it follows the league verification rules. Please ensure you follow the weighing section of the rules and submit a clip that meets all of those requirements.</p>
                            </div>
                          </div>
                        }
                      </>
                    }
                  </div>
                  <div className="col col-2"></div>
                  <div className="formline">
                    {
                      (this.state.editing) ?
                        <>
                          <button className={ "btn btn-edit-catch" + ((this.state.done) ? ' btn-primary' : ' btn-cancel')} onClick={this.submitFish}>Edit Catch</button>
                          <button className="btn btn-cancel" onClick={this.cancelEdit}>Cancel</button>
                        </>
                      :
                        <button className={ "btn" + ((this.state.done) ? ' btn-primary' : ' btn-cancel')} onClick={this.submitFish}>Add Catch</button>
                    }
                  </div>
                </div>
                {
                  (catches) &&
                  <>
                    <h4 data-scrollto="catch-list">{ (overlay && overlay.owner && overlay.owner !== uid && usernames && usernames[overlay.owner] && usernames[overlay.owner].display_name) ? usernames[overlay.owner].display_name+'\'s' : 'Your' } Past Catches</h4>
                    <div className="catches-list">
                      { 
                        objToArray(catches).filter(fish => typeof fish.species !== 'undefined').sort((a, b) => b.dateTime - a.dateTime).map(fish => {
                          return <div className="caught-fish" key={fish._id}>
                            <div className="fish-image">
                              <img src={ (species[fish.species]) ? species[fish.species].img : '/img/species-unknown.png'  } />
                              <div className="fish-name">
                                <span>{ (species[fish.species]) ? species[fish.species].display_name : fish.species }</span>
                              </div>
                            </div>
                            <div className="fish-weight">
                              <span>{ (fish.weight > 0) ? fish.weight+' lbs' : 'Unknown' } </span>
                              <span className="fish-length">{ (fish.length && fish.length > 0) ? parseFloat(fish.length)+' in' : 'Unknown' }</span>
                            </div>
                            <div className="fish-datetime">
                              <span><TimeAgo date={ fish.dateTime.toDate() } /></span>
                            </div>
                            {
                              (fish.askVerify && fish.verified !== true) &&
                              <div className="fish-verified">
                                <span className="material-icons">verified</span><span className="fish-verified-label">Pending</span>
                              </div>
                            }
                            {
                              (fish.verified === true) &&
                              <div className="fish-verified fish-verified-true">
                                <span className="material-icons">verified</span><span className="fish-verified-label">Verified</span>
                              </div>
                            }
                            <div className="fish-buttons">
                              {
                                (this.state.toggleDelete === fish._id) ?
                                  <div className="delete-confirm-container">
                                    <span className="delete-confirm">Delete this catch?</span>
                                    <div className="delete-confirm-buttons">
                                      <button onClick={ () => this.confirmDelete(fish._id) } className="btn btn-error btn-confirm"><span className="material-icons">done</span></button>
                                      <button onClick={ () => this.cancelDelete() } className="btn btn-cancel"><span className="material-icons">close</span></button>
                                    </div>
                                  </div>
                                :
                                <>
                                  {
                                    (fish.clip) &&
                                    <a className="btn btn-primary btn-clip" href={ fish.clip } target="_blank"><span className="material-icons">video_library</span></a>
                                  }
                                  {
                                    (fish.locked) ? 
                                      <div className="fish-locked">
                                        <span className="material-icons">lock</span>
                                      </div>
                                    :
                                    <>
                                      <button onClick={ () => this.editCatch(fish._id) } className="btn btn-primary btn-edit"><span className="material-icons">mode_edit</span></button>
                                      <button onClick={ () => this.toggleDelete(fish._id) } className="btn btn-error btn-delete"><span className="material-icons">delete</span></button>
                                    </>
                                  }
                                </>
                              }
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </>
                }
              </>
            :
              (overlay.owner === uid) ?
              <div>
                <div className="alert-msg alert-danger">
                  <span className="material-icons">warning</span>
                  <span className="alert-msg-text">Your free trial of GO League has expired.</span>
                  <a className="btn btn-primary" onClick={() => buyProduct('prod_JU4W0jhcDAYBGH', 'price_1Ir6NABLQNYQpfx9CjFiZUt7', uid)}>Subscribe Now</a>
                </div>
              </div>
              :
              (usernames && usernames[overlay.owner]) &&
              <div>
                <div className="alert-msg alert-danger">
                  <span className="material-icons">warning</span>
                  <span className="alert-msg-text">{ usernames[overlay.owner].display_name } does not currently have an active subscription.</span>
                </div>
              </div>
          }
          </div>
        </section>
        <Foot />
      </>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const uid = state.firebase.auth.uid;
  const me = (state.firestore.data.users && state.firestore.data.users[uid]) ? Object.assign({}, state.firestore.data.users[uid]) : {};
  const mySubs = (state.firestore.data.mySubs) ? Object.assign({}, state.firestore.data.mySubs) : {};
  me.subs = objToArray(mySubs);
  let overlay = {};
  let overlayKey = (ownProps.match.params.id) ? ownProps.match.params.id : me.overlayKey ;
  if (state.firestore.data.overlays && state.firestore.data.overlays[overlayKey]) {
    overlay = { ...state.firestore.data.overlays[overlayKey] };
  }
  if (me.email) {
    delete me.email;
  }
  return {
    uid : uid,
    me : me,
    overlay : overlay,
    usernames : state.firestore.data.usernames,
    mods : (state.firestore.data.mods && state.firestore.data.mods[uid] && state.firestore.data.mods[uid].mods  && state.firestore.data.mods[uid].mods.length > 0) ? state.firestore.data.mods[uid].mods : null,
    goleague : (state.firestore.data.settings && state.firestore.data.settings.goleague) ? state.firestore.data.settings.goleague : {},
    species : (state.firestore.data.settings && state.firestore.data.settings.goleague && state.firestore.data.settings.goleague.species) ? state.firestore.data.settings.goleague.species : {},
    catches : state.firestore.data.catches
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submitFish: (data, me, callback) => dispatch(submitFish(data, me, callback)),
    updateFish: (id, data, me, callback) => dispatch(updateFish(id, data, me, callback)),
    deleteFish: (id, callback) => dispatch(deleteFish(id, callback)),
    buyProduct: (id, price, uid) => dispatch(buyProduct(id, price, uid))
  }
};

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'users', doc : props.uid,
      subcollections: [{ collection: 'subscriptions' }],
      storeAs: 'mySubs' },
    { collection: 'overlays', doc : (!props.match.params.id) ? (props.me && props.me.overlayKey) ? props.me.overlayKey  : '' : props.match.params.id },
    { collection: 'usernames' },
    { collection: 'mods', doc : props.uid },
    { collection: 'settings', doc : 'goleague' },
    { collection: 'catches', 
      where: [
        ['owner', '==', (!props.match.params.id) ? props.uid : (props.overlay.owner) ? props.overlay.owner : '1234' ]
      ]
    }
  ])
)(Catches))