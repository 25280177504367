import React, { Component } from "react";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import autoBind from 'react-autobind';
import { joinWaitlist, leaveWaitlist } from '../../actions/joinActions';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Foot from "../layout/Foot";
let Carousel = require('react-responsive-carousel').Carousel;

class Join extends Component {

  constructor(props){
    super(props);
    this.state = {
      attempted : false,
      inputs : {}
    }
    autoBind(this);
  }

  componentDidUpdate(){
    if (this.props.me && this.props.me.joinedWaitlist === true && Object.keys(this.props.me.waitlist).length > 0 && Object.keys(this.state.inputs).length === 0) {
      this.setState({
        inputs: {
          ...this.props.me.waitlist
        }
      });
    } else if (this.props.me && this.props.me.joinedWaitlist === false && this.props.me.waitlist === undefined && Object.keys(this.state.inputs).length > 0 && this.state.reset === true) {
      let state = Object.assign({}, this.state);
      state.reset = undefined;
      state.attempted = false;
      for (let key of Object.keys(state.inputs)) {
        state.inputs[key] = "";
      }
      this.setState(state);
    }
  }

  joinList() {
    this.setState({
      attempted : true,
      reset : undefined
    }, function() {
      if ((this.state.attempted && this.streamedFishing && this.streamedPast && this.thisYear && this.targetBass && this.targetStart) && (this.streamedFishing.value !== "" && this.streamedPast.value !== "" && this.thisYear.value !== "" && this.targetBass.value !== "" && this.targetStart.value !== "")) {
        this.props.joinWaitlist({
          streamedFishing : this.streamedFishing.value,
          streamedPast : this.streamedPast.value,
          thisYear : this.thisYear.value,
          targetBass : this.targetBass.value,
          targetStart : this.targetStart.value,
          comments : this.comments.value
        });
      }
    });
  }

  leaveList() {
    this.setState({
      reset : true
    }, function() {
      this.props.leaveWaitlist(() => {
        
      });
  });
  }

  handleChange(e) {
    let inputs = this.state.inputs;
    inputs[e.target.name] = e.target.value
    this.setState({
      inputs: {
        ...inputs
      }
    });
  }

  render(){
    const { uid, stripeRole } = this.props;
    const isPremium = () => {
      return (stripeRole === 'premium');
    };
    return (
      <>
        <section className="hero">
          <div className="container">
            <h1>Join the Waitlist</h1>
            <h2>Stay informed and let us know that you are interested</h2>
            <div className="join-box">
            <div className="steps-bar">
              <div className="progress-track"></div>
                <div id="step1" className={ (uid) ? 'progress-step is-complete' : 'progress-step is-active' }>
                  Step One
                </div>
                <div id="step2" className={ (uid) ? ((this.props.me && this.props.me.waitlist) ? 'progress-step is-complete' : 'progress-step is-active') : 'progress-step' }>
                  Step Two
                </div>
                <div id="step4" className={ (this.props.me && this.props.me.waitlist) ? 'progress-step is-complete' : 'progress-step' }>
                  Complete
                </div>
              </div>
              {
                (!uid) ?
                <div className="twitch-connect">
                  <p>Connect your Twitch account to join the waitlist.</p>
                  <a href={ (window.location.hostname === 'localhost') ? "http://localhost:5001/go-league/us-central1/redirect" : "https://us-central1-go-league.cloudfunctions.net/redirect" }>
                    <img src="/img/twitch-connect.jpg" />
                  </a>
                </div>
                :
                <div className="questions">
                  {
                    ((this.state.attempted && this.streamedFishing && this.streamedPast && this.thisYear && this.targetBass) && (this.streamedFishing.value === "" || this.streamedPast.value === "" || this.thisYear.value === "" || this.targetBass.value === "")) && 
                    <p className="errMsg">Please complete the questions below and resubmit the form to join the waitlist.</p>
                  }
                  {
                    (this.props.me && this.props.me.waitlist) && 
                    <p className="successMsg">You are currently on the waitlist.</p>
                  }
                  <div className={ (this.state.attempted && this.streamedFishing && this.streamedFishing.value === "") ? 'error formline' : 'formline' }>
                    <label htmlFor="streamedFishing">Do you currently livestream fishing?</label>
                    <select ref={el => this.streamedFishing=el} value={this.state.inputs.streamedFishing} onChange={this.handleChange} name="streamedFishing">
                      <option value="">Select...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div className={ (this.state.attempted && this.streamedPast && this.streamedPast.value === "") ? 'error formline' : 'formline' }>
                    <label htmlFor="streamedPast">If not, have you livestreamed fishing in the past?</label>
                    <select ref={el => this.streamedPast=el} value={this.state.inputs.streamedPast} onChange={this.handleChange} name="streamedPast">
                      <option value="">Select...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div className={ (this.state.attempted && this.thisYear && this.thisYear.value === "") ? 'error formline' : 'formline' }>
                    <label htmlFor="thisYear">Do you plan on streaming fishing this year?</label>
                    <select ref={el => this.thisYear=el} value={this.state.inputs.thisYear} onChange={this.handleChange} name="thisYear">
                      <option value="">Select...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div className={ (this.state.attempted && this.targetBass && this.targetBass.value === "") ? 'error formline' : 'formline' }>
                    <label htmlFor="targetBass">Can you target largemouth, smallmouth, or spotted bass in your area?</label>
                    <select ref={el => this.targetBass=el} value={this.state.inputs.targetBass} onChange={this.handleChange} name="targetBass">
                      <option value="">Select...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div className={ (this.state.attempted && this.targetStart && this.targetStart.value === "") ? 'error formline' : 'formline' }>
                    <label htmlFor="targetStart">Can you legally target these bass species starting May 15th, 2021 or earlier in your area?</label>
                    <select ref={el => this.targetStart=el} value={this.state.inputs.targetStart} onChange={this.handleChange} name="targetStart">
                      <option value="">Select...</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div className="formline">
                    <label htmlFor="comments">Enter any questions/comments/concerns you may have:</label>
                    <textarea ref={el => this.comments=el} value={this.state.inputs.comments} onChange={this.handleChange} name="comments"></textarea>
                  </div>
                  <div className="formline buttons">
                    <button onClick={ this.joinList } className="btn btn-primary">{ (this.props.me && this.props.me.waitlist) ? 'Update' : 'Join' }</button>
                    {
                      (this.props.me && this.props.me.waitlist) &&
                      <button onClick={ this.leaveList } className="btn btn-cancel">Leave</button>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="hero-water"></div>
        </section>
        <Foot />
      </>
    );
  };
}


const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid;
  const me = (state.firestore.data.users && state.firestore.data.users[uid]) ? Object.assign({}, state.firestore.data.users[uid]) : {};
  if (me.email) {
    delete me.email;
  }
  return {
    uid : uid,
    me : me
  };
};

const mapDispatchToProps = dispatch => {
  return {
    joinWaitlist: (data) => dispatch(joinWaitlist(data)),
    leaveWaitlist: (callback) => dispatch(leaveWaitlist(callback))
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'users', doc: props.uid }
  ])
)(withRouter(Join))