import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Foot = ({ signOut, uid, profile, isHome, admin, beta, showNav, mod, stripeRole }) => {
  const isAdmin = () => {
    if (admin && typeof admin.global !== 'undefined' && admin.global === 1) {
      return true;
    }
  };
  const isPremium = () => {
    if (stripeRole === 'premium') {
      return true;
    }
  };
  const popTerms = () => {
    
  };
  return (
    <footer>
      <div className="container">
        <div className="socials">
          <div className="social">
            <a href="https://twitter.com/gamersoutdoors" target="_blank"><img src="/img/icon-twitter.png" alt=""/></a>
          </div>
          <div className="social">
            <a href="https://discord.gg/wrYkxsWv9k" target="_blank"><img src="/img/icon-discord.png" alt=""/></a>
          </div>
          <div className="social">
            <a href="https://www.youtube.com/channel/UC8UPjTRj574-Hc0GcovHiFg" target="_blank"><img src="/img/icon-youtube.png" alt=""/></a>
          </div>
          <div className="social">
            <a href="https://www.instagram.com/gamersoutdoors/" target="_blank"><img src="/img/icon-instagram.png" alt=""/></a>
          </div>
          <div className="social">
            <a href="https://www.tiktok.com/@gamersoutdoors?lang=en" target="_blank"><img src="/img/icon-tiktok.png" alt=""/></a>
          </div>
        </div>
        <p>&copy; goleague.tv | Gamers Outdoors | Reel Up North, LLC.</p>
        <p className="terms-links"><Link to="/terms">Terms of Service</Link> &bull; <Link to="/rules">League Rules</Link> &bull; <Link to="/privacy">Privacy Policy</Link></p>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid;
  return {
    uid: uid,
  };
};

export default connect(mapStateToProps)(Foot);
