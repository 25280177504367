import React, { Component } from "react";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import autoBind from 'react-autobind';
import TimeAgo from 'react-timeago';
import Foot from "../layout/Foot";

class WaitlistAdmin extends Component {

  constructor(props){
    super(props);
    this.state = {
      
    }
    autoBind(this);
  }

  render(){
    const { uid, stripeRole, users, admin } = this.props;
    if (!admin || typeof admin.global !== 'number' || admin.global > 1) return <Redirect to="/" />;
    if (!uid) return <Redirect to="/" />;
    const isPremium = () => {
      return (stripeRole === 'premium');
    };
    const parseList = (users) => {
      let arr = [];
      if (typeof users !== 'undefined' && users !== null) {
        for (const [key, value] of Object.entries(users)) {
          arr.push({...value, _id : key})
        }
      }
      return arr;
    };
    const totalWaitlist = (joined) => {
      let total = 0;
      if (typeof users !== 'undefined' && users !== null) {
        for (const [key, value] of Object.entries(users)) {
          if (joined) {
            if (typeof value.waitlist !== 'undefined') {
              total += 1;
            }
          } else {
            total += 1;
          }
        }
      }
      return total;
    };
    return (
      <>
        <section className="hero hero-sm">
          <div className="container">
            <h1>Administration</h1>
            <h2>Review the current waitlist below</h2>
          </div>
          <div className="hero-water"></div>
        </section>
        <section>
          <div className="container">
          <table className="totals">
            <thead>
              <tr>
                <th>Total on Waitlist</th>
                <th>Total Signed In</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{ totalWaitlist(true) }</td>
                <td>{ totalWaitlist() }</td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Account Age</th>
                <th>Type</th>
                <th>Views</th>
                <th>Waitlist</th>
                <th>Current</th>
                <th>Past</th>
                <th>Plan to</th>
                <th>Target Bass</th>
              </tr>
            </thead>
            <tbody>
              {
                parseList(users).map((user, index) => (
                  <tr key={index}>
                    <td><a href={'https://twitch.tv/'+user.login} target="_blank">{ user.display_name }</a></td>
                    <td><TimeAgo date={ user.created_at } /></td>
                    <td className="initial-cap">{ user.broadcaster_type }</td>
                    <td>{ user.view_count }</td>
                    <td className="bold">{ (typeof user.waitlist !== 'undefined') ? 'Yes' : 'No' }</td>
                    <td>{ (typeof user.waitlist !== 'undefined') ? user.waitlist.streamedFishing : '---' }</td>
                    <td>{ (typeof user.waitlist !== 'undefined') ? user.waitlist.streamedPast : '---' }</td>
                    <td>{ (typeof user.waitlist !== 'undefined') ? user.waitlist.thisYear : '---' }</td>
                    <td>{ (typeof user.waitlist !== 'undefined') ? user.waitlist.targetBass : '---' }</td>
                  </tr>
                )) 
              }
            </tbody>
          </table>
          </div>
        </section>
        <Foot />
      </>
    );
  };
}


const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid;
  const me = (state.firestore.data.users && state.firestore.data.users[uid]) ? Object.assign({}, state.firestore.data.users[uid]) : {};
  if (me.email) {
    delete me.email;
  }
  return {
    users : state.firestore.data.users,
    uid : uid,
    me : me
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'users' }
  ])
)(withRouter(WaitlistAdmin))