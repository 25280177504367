import React, { Component } from "react";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import autoBind from 'react-autobind';
import { resetWeatherShown, resetCatchesShown, resetRankingsShown, resetBagShown, resetPrizeShown, getProfile, resetRefresh } from '../../actions/overlayActions';
import { objToArray } from '../../utils/Object';
import DateCountdown from 'react-date-countdown-timer';
import TimeAgo from 'react-timeago';
const moment = require('moment-timezone');
moment().format();

class Overlay extends Component {

  constructor(props){
    super(props);
    this.state = {
      user : {},
      donos : [],
      donoReset : false,
      audio : new Audio(window.location.protocol+'//'+window.location.host+'/audio/dono-sound.mp3')
    }
    autoBind(this);
    //
    //
    //
    // fix slobs issue
    // get mods out of list 
    // show live streams on homepage, let visitors of the site test overlays
    // make admin fish pending count in nav
    //
    // Show league you're in, your bag, and rules on settings page
    // streamlabs issues
    // settings to adjust all overlay positions
    // who is online page
    // admin history
    // admin questions and edit ability
    // how to videos
    //
    //
    //make better admin login path
    //fix permission fb errors when logged out
    //new species on fish catch popup
    //time since last catch of type etc on catch popup
    //league wide notifications on rank changes
    //cron job to close leagues enabled=false
    //save other socials in settings, promote on overlays and in discord webhooks
    //live list on site and whos online fishing command 
    //built in tts
    //remote page with toggle switches for overlays and shit
  }

  componentWillUpdate(nextProps, nextState) {
    // if (nextState.open == true && this.state.open == false) {
      
    // }
    if (this.props.settings && this.props.settings.goleague && this.props.settings.goleague.prizepool && this.props.settings.goleague.prizepool.latest && nextProps && nextProps.settings && nextProps.settings.goleague && nextProps.settings.goleague.prizepool && nextProps.settings.goleague.prizepool.latest) {
      if (this.props.settings.goleague.prizepool.latest._id !== nextProps.settings.goleague.prizepool.latest._id) {
        let donos = [...this.state.donos];
        donos.push(nextProps.settings.goleague.prizepool.latest);
        this.setState({
          donos : donos
        });
      }
    }
  }
  
  componentDidUpdate(){
    if (Object.keys(this.state.user).length === 0 && this.props.overlay && this.props.overlay.owner && this.state.calledProfile !== true) {
      this.setState({
        calledProfile : true
      });
      this.props.getProfile(this.props.overlay.owner, (data) => {
        this.setState({
          user : data.user
        });
      });
    }
    if (this.props.overlay && this.props.overlay.refresh === true && window.location.search.indexOf('?refresh=') === -1) {
      window.location.href = window.location.href.replace(window.location.search,'')+'?refresh='+moment().unix();
    } else if (this.props.overlay && this.props.overlay.refresh && window.location.search.indexOf('?refresh=') > -1) {
      if (this.state.refresh !== true) {
        this.setState({
          refresh : true
        });
        this.props.resetRefresh(this.props.match.params.id, (data) => {
          window.location.href = window.location.href.replace(window.location.search,'')+'?done='+moment().unix();
        });
      }
    }
  }

  render(){
    const { stripeRole, settings, weather, match, overlay, resetWeatherShown, resetCatchesShown, resetRankingsShown, resetBagShown, resetPrizeShown, catches, rankings, resetRefresh } = this.props;
    let species = (settings && settings.goleague && settings.goleague.species) ? settings.goleague.species : {};
    if (overlay.active === false) {
      return <></>;
    }
    let isPlaying = (audio) => {
      return audio
        && audio.currentTime > 0
        && !audio.paused
        && !audio.ended
        && audio > 2;
    };
    const isPremium = () => {
      return (stripeRole === 'premium');
    };
    const isLocal = () => {
      return (window.location.hostname === 'localhost');
    };
    const getRank = (uid, league, flag) => {
      let arr = [];
      let rank = 0;
      let leg = [...league].sort((a, b) => b.bagWeight - a.bagWeight);
      if (leg.length === 0) {
        return false;
      }
      let offset = 0;
      leg.forEach((user, index) => {
        let ranked = Object.assign({}, user);
        if (leg[index+1] && typeof leg[index+1].bagWeight !== 'undefined' && leg[index].bagWeight !== leg[index+1].bagWeight) {
          if (offset > 0) {
            rank += offset;
            offset = 0;
          }
          rank+=1;
        } else {
          if (offset === 0) {
            rank += 1;
          }
          if (leg[index+1]) {
            offset +=1;
          }
        }
        if (flag === 1) {
          ranked.rank = rank;
        } else if (flag === 2) {
          ranked.rank = (offset > 0) ? true : false;
        } else {
          ranked.rank = rank + ((offset > 0) ? ' (T)' : '');
        }
        arr.push(ranked);
      });
      let myRank = arr.filter(user => {
        return user._id === uid
      });
      if (myRank[0] && typeof myRank[0].rank !== 'undefined') {
        return myRank[0].rank;
      } else {
        return null;
      }
    };
    const getBag = (uid, league, flag) => {
      let leg = [...league].filter(user => user._id === uid);
      if (leg.length === 0) {
        return false;
      }
      if (flag === 2) {
        return leg[0].bagWeight;
      }
      return leg[0].bag;
    };
    let leagueData = {};
    let rankData = {};
    let multis = {};
    let currentLeague = {};
    if (settings && settings.goleague && settings.goleague.leagues && overlay && overlay.owner) {
      let leagues = settings.goleague.leagues;
      leagues.forEach((league, li) => {
        if (league.starts.toDate() < new Date() && new Date() < league.ends.toDate()) {
          currentLeague = Object.assign({}, league);
          if (league.subleagues) {
            league.subleagues.forEach((subleague, si) => {
              if (overlay.multi) {
                multis = JSON.parse(JSON.stringify(overlay.multi));
                for (let prop in multis) {
                  if (multis.hasOwnProperty(prop)) {
                    if (subleague.participants && typeof subleague.participants[multis[prop]._id] !== 'undefined') {
                      if (rankings && rankings.goleague && rankings.goleague.leagues && rankings.goleague.leagues[li] && rankings.goleague.leagues[li].subleagues && rankings.goleague.leagues[li].subleagues[si]) {
                        multis[prop].rank = getRank(multis[prop]._id, rankings.goleague.leagues[li].subleagues[si].participants);
                        multis[prop].bag = getBag(multis[prop]._id, rankings.goleague.leagues[li].subleagues[si].participants);
                        multis[prop].bagWeight = getBag(multis[prop]._id, rankings.goleague.leagues[li].subleagues[si].participants, 2);
                        multis[prop].userSubleague = si;
                      }
                    }
                  }
                }
              }
              if (subleague.participants && typeof subleague.participants[overlay.owner] !== 'undefined') {
                if (rankings && rankings.goleague && rankings.goleague.leagues && rankings.goleague.leagues[li] && rankings.goleague.leagues[li].subleagues && rankings.goleague.leagues[li].subleagues[si]) {
                  rankData = Object.assign({}, rankings.goleague.leagues[li].subleagues[si]);
                  rankData.participants = rankData.participants.filter(user => subleague.participants[user._id] === subleague.participants[overlay.owner]);
                  rankData.myRank = getRank(overlay.owner, rankData.participants);
                  rankData.myBag = getBag(overlay.owner, rankings.goleague.leagues[li].subleagues[si].participants);
                  rankData.myBagWeight = getBag(overlay.owner, rankings.goleague.leagues[li].subleagues[si].participants, 2);
                }
                leagueData = Object.assign({}, league);
                leagueData.userLeague = li;
                leagueData.userSubleague = si;
                leagueData.pool = leagueData.pool || 0;
              }
            });
          }
        }
      });
    }
    const getPrizePool = () => {
      return parseFloat(currentLeague.pool*(1-settings.goleague.prizepool.fees)).toFixed(2);
    };
    const getPrizeGoal = () => {
      let goal = 0;
      let goals = settings.goleague.prizepool.goals;
      for (let i = goals.length; i >= 0; i--) {
        if (parseFloat(currentLeague.pool*(1-settings.goleague.prizepool.fees)) < goals[i]) {
          goal = goals[i];
        }
      }
      return goal;
    };
    const getCatchCount = (checkAlias) => {
      let now = new Date();
      let start = moment(now).startOf('day').valueOf();
      if (typeof overlay.timezone !== 'undefined') {
        start = moment(now).tz(overlay.timezone).startOf('day').valueOf();
      }
      let count = 0;
      if (catches && objToArray(catches) && objToArray(catches).length > 0) {
        objToArray(catches).filter((fish) => {
          if (checkAlias === true) {
            if (overlay.multi && overlay.showAlias && overlay.multi[overlay.showAlias]) {
              return fish.owner === overlay.multi[overlay.showAlias]._id;
            }
          } else {
            return fish;
          }
        }).filter((fish) => {
          return typeof fish.dateTime !== 'undefined';
        }).filter((fish) => {
          return new Date(fish.dateTime.toDate()).getTime() >= start;
        }).forEach(fish => {
          count += 1;
        });
      }
      return count;
    };
    const getCatches = () => {
      let arr = [];
      let start = new Date();
      start.setHours(0,0,0,0);
      let count = 0;
      if (catches && objToArray(catches) && objToArray(catches).length > 0) {
        objToArray(catches).filter((fish) => {
          return typeof fish.dateTime !== 'undefined';
        }).filter((fish) => {
          return new Date(fish.dateTime.toDate()).getTime() >= start.getTime();
        }).forEach(fish => {
          arr.push(fish);
        });
      }
      return arr;
    };
    const showWeather = () => {
      clearTimeout(window.animTimer);
      clearTimeout(window.animTimer2);
      clearTimeout(window.animTimer3);
      if (typeof window.$ !== 'undefined') {
        let $ = window.$;
        window.animTimer = setTimeout(() => {
          if ($('.weather').hasClass('weather-mode-chat')) {
            let animTo = {};
            let animFrom = {};
            if ($('.weather').hasClass('weather-align-right')) {
              animTo.right = "0";
              animFrom.right = "-100%";
              delete animTo.left;
              delete animFrom.left;
            } else {
              animTo.left = "0";
              animFrom.left = "-100%";
              delete animTo.right;
              delete animFrom.right;
            }
            $('.weather').attr('style','');
            $('.weather').animate(animTo, 2500, function() {
              window.animTimer2 = setTimeout(() => {
                if ($('.weather').hasClass('weather-mode-chat')) {
                  $('.weather').animate(animFrom, 2500, function() {
                    $('.weather').attr('style','');
                    window.animTimer3 = setTimeout(() => {
                      if ($('.weather').hasClass('weather-mode-chat')) {
                        resetWeatherShown(match.params.id);
                      } else {
                        $('.weather').attr('style','');
                      }
                    }, 5000);
                  });
                } else {
                  $('.weather').attr('style','');
                }
              },10000);
            });
          }
        },1000);
      }
    };
    const animWeather = () => {
      clearTimeout(window.animTimer);
      clearTimeout(window.animTimer2);
      clearTimeout(window.animTimer3);
      if (typeof window.$ !== 'undefined') {
        let $ = window.$;
        window.animTimer = setTimeout(() => {
          if ($('.weather').hasClass('weather-mode-anim')) {
            let animTo = {};
            let animFrom = {};
            if ($('.weather').hasClass('weather-align-right')) {
              animTo.right = "0";
              animFrom.right = "-100%";
              delete animTo.left;
              delete animFrom.left;
            } else {
              animTo.left = "0";
              animFrom.left = "-100%";
              delete animTo.right;
              delete animFrom.right;
            }
            $('.weather').attr('style','');
            $('.weather').animate(animTo, 2500, function() {
              window.animTimer2 = setTimeout(() => {
                if ($('.weather').hasClass('weather-mode-anim')) {
                  $('.weather').animate(animFrom, 2500, function() {
                    $('.weather').attr('style','');
                    window.animTimer3 = setTimeout(() => {
                      if ($('.weather').hasClass('weather-mode-anim')) {
                        animWeather();
                      } else {
                        $('.weather').attr('style','');
                      }
                    }, ($('.weather').data('seconds')*1));
                  });
                } else {
                  $('.weather').attr('style','');
                }
              },10000);
            });
          }
        },1000);
      }
    };
    const showCatches = () => {
      clearTimeout(window.fishTimer);
      clearTimeout(window.fishTimer2);
      if (typeof window.$ !== 'undefined') {
        let $ = window.$;
        window.fishTimer = setTimeout(() => {
          $('.catches-card-overlay').animate({
            opacity : 1
          }, 1000, function() {
            if ($('.fish-catch-scroll-item').length > 0) {
              let width = 0;
              $('.fish-catch-scroll-item').each(function(index){
                if (index < $('.fish-catch-scroll-item').length - 1) {
                  width -= $(this).width();
                } else {
                  width -= 5;
                }
              });
              $('.fish-catch-scroll').animate({
                left : ((width === 0) ? '0' : width) +'px'
              }, (($('.fish-catch-scroll-item').length - 1)*8000), 'linear', function() {
                window.fishTimer2 = setTimeout(() => {
                  $('.catches-card-overlay').animate({
                    opacity : 0
                  }, 1000, function() {
                    resetCatchesShown(match.params.id);
                  });
                },5000);
              });
            } else {
              window.fishTimer2 = setTimeout(() => {
                $('.catches-card-overlay').animate({
                  opacity : 0
                }, 1000, function() {
                  resetCatchesShown(match.params.id);
                });
              },12000);
            }
          });
        },1000);
      }
    };
    const showRanks = () => {
      clearTimeout(window.rankTimer);
      clearTimeout(window.rankTimer2);
      clearTimeout(window.rankTimer3);
      if (typeof window.$ !== 'undefined') {
        let $ = window.$;
        window.rankTimer = setTimeout(() => {
          if ($('.rank-mask-inner').css('margin-left') === '0px') {
            $('.rank-card-overlay').animate({
              opacity : 1
            }, 1000, function() {
              window.rankTimer3 = setTimeout(() => {
                let width = 0;
                $('.rank-card-overlay .catches-player').each(function(index){
                  if (index < $('.rank-card-overlay .catches-player').length - 2) {
                    width -= $(this).width();
                  } else {
                    width -= 5;
                  }
                });
                window.rankTimer2 = setTimeout(() => {
                  $('.rank-card-overlay').animate({
                    opacity : 0
                  }, 1000, function() {
                    resetRankingsShown(match.params.id);
                  });
                },8000);
                $('.rank-mask-inner').animate({
                  marginLeft : ((width === 0) ? '0' : width) +'px'
                }, (($('.rank-card-overlay .catches-player').length - 1)*2000), 'linear', function() {
                  $('.rank-card-overlay').animate({
                    opacity : 0
                  }, 1000, function() {
                    resetRankingsShown(match.params.id);
                  });
                });
              },4000);
            });
          }
        },1000);
      }
    };
    const showBag = () => {
      clearTimeout(window.bagTimer);
      clearTimeout(window.bagTimer2);
      if (typeof window.$ !== 'undefined') {
        let $ = window.$;
        window.bagTimer = setTimeout(() => {
            $('.rank-card').animate({
              opacity : 1
            }, 1000, function() {
              window.bagTimer2 = setTimeout(() => {
                $('.rank-card').animate({
                  opacity : 0
                }, 1000, function() {
                  resetBagShown(match.params.id);
                });
              }, 8000);
            });
        },1000);
      }
    };
    const showPrize = () => {
      clearTimeout(window.prizeTimer);
      clearTimeout(window.prizeTimer2);
      if (typeof window.$ !== 'undefined') {
        let $ = window.$;
        window.prizeTimer = setTimeout(() => {
            $('.prize-pool').animate({
              opacity : 1
            }, 1000, function() {
              window.prizeTimer2 = setTimeout(() => {
                $('.prize-pool').animate({
                  opacity : 0
                }, 1000, function() {
                  resetPrizeShown(match.params.id);
                });
              }, 12000);
            });
        },1000);
      }
    };
    let that = this;
    const donoAlert = (donos, audio, volume) => {
      clearTimeout(window.donoTimer);
      clearTimeout(window.donoTimer2);
      if (typeof window.$ !== 'undefined') {
        let $ = window.$;
        window.donoTimer = setTimeout(() => {
            $('.dono-alert').animate({
              opacity : 1
            }, 1000, function() {
              if (!isPlaying(audio)) {
                audio.volume = parseFloat(volume);
                audio.play();
              }
              window.donoTimer2 = setTimeout(() => {
                $('.dono-alert').animate({
                  opacity : 0
                }, 1000, function() {
                  let arr = [];
                  if (donos.length > 1) {
                    donos.forEach((tip, index) => {
                      if (index > 0) {
                        arr.push(tip);
                      }
                    });
                  }
                  that.setState({
                    donos : arr,
                    donoReset : true
                  });
                });
              }, 8000);
            });
        },1000);
      }
    };
    const donoReset = () => {
      clearTimeout(window.donoResetTimer);
      if (typeof window.$ !== 'undefined') {
        let $ = window.$;
        window.donoTimer3 = setTimeout(() => {
          that.setState({
            donoReset : false
          });
        }, 2000);
      }
    };
    return (
      <>
        <div className="overlay" style={{ height: (typeof overlay.advanced !== 'undefined' && typeof overlay.advanced.overlayHeight !== 'undefined') ? overlay.advanced.overlayHeight+'px' : '1080px', width: (typeof overlay.advanced !== 'undefined' && typeof overlay.advanced.overlayWidth !== 'undefined') ? overlay.advanced.overlayWidth+'px' : '1920px' }}>
          <style>{"\
            html,body,#root,.overlay{\
              background:rgba(0,0,0,0) !important;\
            }\
            .Toastify, .spinner-grow {\
              display:none !important;\
            }\
            .weather::before {\
              opacity:"+ ((overlay && overlay.weather && typeof overlay.weather.opacity !== 'undefined') ? ((overlay.weather.opacity/100).toFixed(2)) : '0.8') +";\
            }\
          "}</style>
          {
            (weather && weather.current && weather.location && overlay && overlay.weather) && 
            <div className={"weather"+((overlay.weather.style && overlay.weather.style !== 'vertCard')?(' weather-'+overlay.weather.style):' weather-vertical')+((overlay.weather.mode)?(' weather-mode-'+overlay.weather.mode):' weather-mode-always')+((overlay.weather.align)?(' weather-align-'+overlay.weather.align):' weather-align-left')} data-seconds={(typeof overlay.weather.secs !== 'undefined') ? (overlay.weather.secs*1000) : 180000}>
              {
                (overlay.showWeather) &&
                (
                  showWeather()
                )
              }
              {
                (overlay.weather.mode === 'anim') &&
                (
                  animWeather()
                )
              }
              <div className="weather-inner-top">
                <div className="weather-inner">
                  <span className="location">{ weather.location.region }, { weather.location.country }</span>
                  <div className="current">
                    <div className="current-icon">
                      <div className="icon"><img src={ weather.current.condition.icon.replace('64x64','128x128') } /></div>
                      <div className="temp">
                        <span className="tempf">{ Math.round(weather.current.temp_f) }&deg;F</span>
                        <span className="tempc">{ Math.round(weather.current.temp_c) }&deg;C</span>
                      </div>
                    </div>
                    <span className="conditions">{ weather.current.condition.text }</span>
                  </div>
                  <div className="extras">
                    <div className="extras-row humidity">
                      <div className="icon"><img src="/img/icon-humidity.png" /></div>
                      <div className="info">{ Math.round(weather.current.humidity) }%</div>
                    </div>
                    <div className="extras-row pressure">
                      <div className="icon"><img src="/img/icon-pressure.png" /></div>
                      <div className="info">{ weather.current.pressure_in } <span className="small">in</span></div>
                    </div>
                    <div className="extras-row wind">
                      <div className="icon"><img src="/img/icon-wind.png" /></div>
                      <div className="info"><span className="small">{ weather.current.wind_dir }</span> { Math.round(weather.current.wind_mph) } <span className="small">MPH</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            (this.state.donos.length > 0 && this.state.donoReset === true) &&
            <>
              {
                donoReset()
              }
            </>
          }
          {
            (settings && settings.goleague && settings.goleague.prizepool && settings.goleague.prizepool.alerts && this.state.donos.length > 0 && this.state.donoReset !== true) &&
            <div className="dono-alert">
              <div className="dono-alert-inner">
                <img className="dono-alert-logo" src="/img/go-league-logo-full.png" />
                <div className="dono-alert-text">
                  <span><span className="dono-username">{ this.state.donos[0].display_name }</span> just donated <span className="dono-amount">${ this.state.donos[0].amount }</span> to the GO League prize pool!</span>
                </div>
              </div>
              
              {
                donoAlert(this.state.donos, this.state.audio, (this.state.overlay && this.state.overlay.advanced && this.state.overlay.advanced.volume) ? this.state.overlay.advanced.volume : 1)
              }
            </div>
          }
          {
            (overlay.catches && overlay.catches.icon === 'yes') && 
            <div className="catches-icon" style={{ top: (typeof overlay.catches.iconOffsetY !== 'undefined') ? overlay.catches.iconOffsetY+'px' : '45px', right: (typeof overlay.catches.iconOffsetX !== 'undefined') ? overlay.catches.iconOffsetX+'px' : '70px' }}>
              <span className="catches-label">Fish Caught</span>
              <div className="catches-inner">
                <span className="catches-count">{ getCatchCount() }</span>
              </div>
            </div>
          }
          {
            (this.state.user.display_name && settings && settings.goleague && overlay && overlay.showCatches) &&
            <div className="catches-card-overlay" style={{ bottom: (typeof overlay.catches.cardOffsetY !== 'undefined') ? overlay.catches.cardOffsetY+'px' : '80px' }}>
              {
                (overlay.showCatches) &&
                (
                  showCatches()
                )
              }
              {
                ((multis && overlay.showAlias && typeof multis[overlay.showAlias].userSubleague === 'number') || (typeof overlay.multi === 'undefined' && leagueData && typeof leagueData.userSubleague === 'number')) &&
                <div className="player-photo"><img src={ (multis && overlay.showAlias) ? (([...rankData.participants].filter(user => user._id === overlay.multi[overlay.showAlias]._id)[0].league_photo) ? [...rankData.participants].filter(user => user._id === overlay.multi[overlay.showAlias]._id)[0].league_photo : '/img/profile-unknown.png') : ((this.state.user.league_photo) ? this.state.user.league_photo : '/img/profile-unknown.png') } /></div>
              }
              <div className="catches-card catches-logos">
                <div className="skew">
                  <div className="skew-inner">
                    <div className="logo-img">
                      <img src="/img/go-league-logo-full.png" />
                    </div>
                  </div>
                  {
                    (currentLeague && currentLeague.ends) ?
                    <span className="logo-text">League Ends in <DateCountdown dateTo={ currentLeague.ends.toDate() } locales={['','','','','','']} locales_plural={['','','','','','']} numberOfFigures={1} mostSignificantFigure='day'  /> Days</span>
                    :
                    <span className="logo-text">Visit goleague.tv</span>
                  }
                </div>
              </div>
              {
                ((multis && overlay.showAlias && typeof multis[overlay.showAlias].userSubleague === 'number') || (typeof overlay.multi === 'undefined' && leagueData && typeof leagueData.userSubleague === 'number')) &&
                <div className="catches-card catches-player">
                  <div className="player-info">
                    <div className="player-info-inner">
                      <div className="player-info-skew">
                        <div className="player-info-skew-inner">
                          <span className="player-name"><span className="player-name-text">{ (overlay.multi && overlay.showAlias) ? overlay.showAlias : this.state.user.display_name }</span></span>
                          <span className="player-league">
                            <span className="player-league-name">{ settings.goleague.regions[leagueData.subleagues[leagueData.userSubleague].participants[(overlay.multi && overlay.showAlias && overlay.multi[overlay.showAlias] && overlay.multi[overlay.showAlias]._id) ? overlay.multi[overlay.showAlias]._id : overlay.owner]].display_name } { leagueData.display_name }</span>
                            <span className="player-league-division">
                              <span className="player-name-rank">{ (overlay.multi && overlay.showAlias && overlay.multi[overlay.showAlias] && overlay.multi[overlay.showAlias]._id) ? '#'+getRank(overlay.multi[overlay.showAlias]._id, rankData.participants) : ((typeof rankData.myRank !== 'undefined' && rankData.myRank !== null && rankData.myRank !== '') ? ('#'+rankData.myRank) : '#NR') }</span>
                              <span className="player-name-division-text">{ leagueData.subleagues[leagueData.userSubleague].display_name } League</span>
                              </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className={'catches-card catches-fish'+(((multis && overlay.showAlias && typeof multis[overlay.showAlias].userSubleague === 'number') || (typeof overlay.multi === 'undefined' && leagueData && typeof leagueData.userSubleague === 'number')) ? '' : ' no-league')}>
                <div className="skew">
                  <div className="skew-inner">
                    <div className="fish-catch-count">
                      <span className="fish-catch-count-text">{ getCatchCount((typeof overlay.multi !== 'undefined')) } <span className="fish-catch-count-label">Caught Today</span></span>
                    </div>
                    <div className="fish-catch-scroller">
                      <div className="fish-catch-scroller-inner">
                        <div className="fish-catch-scroll">
                          {
                            (getCatchCount((typeof overlay.multi !== 'undefined')) > 0) ? 
                              getCatches().filter((fish) => {
                                if (overlay.multi && overlay.showAlias && overlay.multi[overlay.showAlias]) {
                                  return fish.owner === overlay.multi[overlay.showAlias]._id;
                                } else {
                                  return fish;
                                }
                              }).filter(fish => typeof fish.species !== 'undefined').sort((a, b) => a.dateTime - b.dateTime).map((fish, index) => {
                                return <div key={ fish._id } className="fish-catch-scroll-item">
                                  <div className="fish-catch-number">
                                    <span className="fish-number">{ index+1 }</span>
                                  </div>
                                  <div className="fish-catch-species">
                                    <div className="fish-catch-img">
                                      <img src={ (species[fish.species]) ? species[fish.species].img : '/img/species-unknown-light.png' } />
                                    </div>
                                  </div>
                                  <div className="fish-catch-details">
                                    <div className="fish-catch-name">
                                      <span>{ (species[fish.species]) ? species[fish.species].display_name : fish.species }</span>
                                    </div>
                                    <div className="fish-catch-info">
                                      <span className="fish-catch-info-weight">
                                        <span className="fish-catch-info-text">
                                          { (fish.weight > 0) ? fish.weight.toFixed(2) : '---' }
                                        </span>
                                        <span className="fish-catch-info-label">
                                          lbs
                                        </span>
                                      </span>
                                      <span className="fish-catch-info-length">
                                        <span className="fish-catch-info-text">
                                          { (fish.length && fish.length > 0) ? parseFloat(fish.length) : '---' }
                                        </span>
                                        <span className="fish-catch-info-label">
                                          in
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              })
                            :
                            <div className="fish-catch-none">
                              <span className="fish-catch-none-text">No fish have been caught today...</span>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {
            (leagueData && leagueData.ends && leagueData.ends.toDate() > new Date() && leagueData.ends.toDate() - new Date() < 259200000) &&
            <div className="league-ends-timer">
              <div className="league-ends-text"><span>League Ends in</span></div>
              <div className="league-ends-time">
                <DateCountdown dateTo={ currentLeague.ends.toDate() } locales={['Y','M','D','H','M','S']} locales_plural={['Y','M','D','H','M','S']} mostSignificantFigure='hour'  />
              </div>
            </div>
          }
          {
            (this.state.user.display_name && settings && settings.goleague && overlay && rankData && typeof rankData.myRank !== 'undefined' && overlay.showRankings === true) &&
            <div className="rank-card-overlay" style={{ bottom: (overlay.catches && typeof overlay.catches.rankOffsetY !== 'undefined') ? overlay.catches.rankOffsetY+'px' : '80px' }}>
              {
                (overlay.showRankings) &&
                (
                  showRanks()
                )
              }
              <div className="catches-card catches-logos">
                <div className="skew">
                  <div className="skew-inner">
                    <div className="logo-img">
                      <img src="/img/go-league-logo-full.png" />
                    </div>
                  </div>
                  <span className="logo-text">League Ends in <DateCountdown dateTo={ leagueData.ends.toDate() } locales={['','','','','','']} locales_plural={['','','','','','']} numberOfFigures={1} mostSignificantFigure='day'  /> Days</span>
                </div>
              </div>
              <div className="rank-mask">
                <div className="rank-mask-inner">
                  {
                    [...rankData.participants].filter((u,i) => i < 9).map(user => {
                      return <div key={user._id} className="catches-card catches-player">
                        <div className="catches-card-inner">
                          <div className="player-info">
                            <div className="player-info-inner">
                              <div className="player-photo"><img src={ (user.league_photo) ? user.league_photo : '/img/profile-unknown.png' } /></div>
                              <div className="player-rank">
                                <span className="rank-name">#{ getRank(user._id, rankData.participants, 1) }</span>
                                {
                                  (getRank(user._id, rankData.participants, 2)) &&
                                  <span className="rank-tied">Tied</span>
                                }
                              </div>
                              <div className="player-info-skew">
                                <div className="player-info-skew-inner">
                                  <span className="player-name"><span className="player-name-text">{ user.display_name }</span></span>
                                  <span className="player-league">
                                    <span className="player-league-division">
                                      <span className="player-name-division-text">{ user.bagWeight } lbs.</span>
                                    </span>
                                    <span className="player-league-name">{ user.bag.length } Fish</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
          }
          {
            (this.state.user.display_name && settings && settings.goleague && overlay && rankData && typeof rankData.myRank !== 'undefined' && overlay.showBag) &&
            <div className="rank-card" style={{ bottom: (typeof overlay.catches.bagOffsetY !== 'undefined') ? overlay.catches.bagOffsetY+'px' : '0px', right: (typeof overlay.catches.bagOffsetX !== 'undefined') ? overlay.catches.bagOffsetX+'px' : '0px' }}>
              {
                (overlay.showBag) &&
                (
                  showBag()
                )
              }
              <div className="rank-card-inner">
                <div className="rank-player">
                  <div className="logo-img">
                    <img src="/img/go-league-logo-full.png" />
                  </div>
                  <div className="player-info">
                    <div className="player-photo"><img src={ (multis && overlay.showAlias) ? (([...rankData.participants].filter(user => user._id === overlay.multi[overlay.showAlias]._id)[0].league_photo) ? [...rankData.participants].filter(user => user._id === overlay.multi[overlay.showAlias]._id)[0].league_photo : '/img/profile-unknown.png') : ((this.state.user.league_photo) ? this.state.user.league_photo : '/img/profile-unknown.png') } /></div>
                    <div className="player-name">
                      <span className="player-name-text">{ (multis && overlay.showAlias) ? overlay.showAlias : this.state.user.display_name }</span>
                    </div>
                  </div>
                </div>
                <div className="rank-info">
                  <div className="stat-rank">{ (overlay.multi && overlay.showAlias && overlay.multi[overlay.showAlias] && overlay.multi[overlay.showAlias]._id) ? '#'+getRank(overlay.multi[overlay.showAlias]._id, rankData.participants) : ((typeof rankData.myRank !== 'undefined' && rankData.myRank !== null && rankData.myRank !== '') ? ('#'+rankData.myRank) : '#NR') }</div>
                  <div className="stat-weight">{ (multis && overlay.showAlias) ? multis[overlay.showAlias].bagWeight : rankData.myBagWeight }</div>
                  <div className="stat-fish">{ (multis && overlay.showAlias) ? multis[overlay.showAlias].bag.length : rankData.myBag.length }/5</div>
                </div>
                <div className="league-info">
                  <span className="league-region">{ settings.goleague.regions[leagueData.subleagues[leagueData.userSubleague].participants[overlay.owner]].display_name }  { leagueData.display_name }</span>
                  <span className="league-name">{ leagueData.subleagues[leagueData.userSubleague].display_name } League</span>
                </div>
                <div className="catch-info">
                  <div className="catch-label">
                    <div className="catch-label-inner">
                      5 Best League Catches
                    </div>
                  </div>
                  <div className="catch-list">
                    {
                      (multis && overlay.showAlias) ?
                        multis[overlay.showAlias].bag.filter(fish => typeof fish.species !== 'undefined').sort((a, b) => b.weight - a.weight).map((fish, index) => {
                          return <div key={ index } className="catch-list-item">
                            <div className="fish-catch-number">
                              <span className="fish-number">{ index + 1 }</span>
                            </div>
                            <div className="fish-catch-species">
                              <div className="fish-catch-img">
                                <img src={ (species[fish.species]) ? species[fish.species].img : '/img/species-unknown-light.png' } />
                              </div>
                            </div>
                            <div className="fish-catch-stats">
                              <span className="fish-catch-command">!bag{ index + 1 }</span>
                              <span className="fish-catch-ago"><TimeAgo date={ fish.dateTime.toDate() } /></span>
                            </div>
                            <div className="fish-catch-details">
                              <div className="fish-catch-info">
                                <span className="fish-catch-info-weight">
                                  <span className="fish-catch-info-text">
                                    { (fish.weight > 0) ? fish.weight.toFixed(2) : '---' }
                                  </span>
                                  <span className="fish-catch-info-label">
                                    lbs
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        })
                      :
                        rankData.myBag.filter(fish => typeof fish.species !== 'undefined').sort((a, b) => b.weight - a.weight).map((fish, index) => {
                          return <div key={ index } className="catch-list-item">
                            <div className="fish-catch-number">
                              <span className="fish-number">{ index + 1 }</span>
                            </div>
                            <div className="fish-catch-species">
                              <div className="fish-catch-img">
                                <img src={ (species[fish.species]) ? species[fish.species].img : '/img/species-unknown-light.png' } />
                              </div>
                            </div>
                            <div className="fish-catch-stats">
                              <span className="fish-catch-command">!bag{ index + 1 }</span>
                              <span className="fish-catch-ago"><TimeAgo date={ fish.dateTime.toDate() } formatter={(value, unit, suffix) => {
                                  if (unit === 'minute') {
                                    if (value > 1) {
                                      unit = 'mins.';
                                    } else {
                                      unit = 'min.';
                                    }
                                  }
                                  return value + ' ' + unit + ' ' + suffix;
                                }} /></span>
                            </div>
                            <div className="fish-catch-details">
                              <div className="fish-catch-info">
                                <span className="fish-catch-info-weight">
                                  <span className="fish-catch-info-text">
                                    { (fish.weight > 0) ? fish.weight.toFixed(2) : '---' }
                                  </span>
                                  <span className="fish-catch-info-label">
                                    lbs
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        })
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          {
            (currentLeague && settings && settings.goleague && overlay && overlay.showPrize) &&
            <div className="prize-pool">
              {
                (overlay.showPrize) &&
                (
                  showPrize()
                )
              }
              <div className="prize-pool-inner">
                <div className="prize-bar">
                  <div className="prize-logo">
                    <img src="/img/go-league-logo-full.png" />
                  </div>
                  <div className="prize-bar-container">
                    <div className="prize-bar-container-inner">
                      <div className="prize-bar-bar-outer">
                        <div className="prize-bar-bar-inner" style={{width : (getPrizePool()/getPrizeGoal()*100)+'%' }}>

                        </div>
                      </div>
                      <div className="prize-amount">
                        <span className="prize-amount-text">${ getPrizePool() } / ${ getPrizeGoal() }</span>
                      </div>
                    </div>
                    <div className="flex-break"></div>
                    <div className="prize-season">
                      <span className="prize-season-text">Season {currentLeague.season} Prize Pool</span>
                    </div>
                    <div className="prize-cta">
                      <span className="prize-cta-inner">
                        Type <span>!Prize</span> to Donate
                      </span>
                    </div>
                  </div>
                </div>
                {
                  (settings.goleague.prizepool.top && settings.goleague.prizepool.top.length > 0) &&
                  <div className="prize-donors">
                    <div className="prize-donors-inner">
                      <div className="prize-donors-label"><span>Top Donors:</span></div>
                      <div className="prize-donors-list">
                        {
                          settings.goleague.prizepool.top.map((donor, index) => {
                            return <div className="prize-donor">
                              <span className="prize-place">{ index+1 }</span>
                              <span className="prize-donor-username">{ donor }</span>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                }
                <div className="prize-distro">
                  <div className="prize-distro-inner">
                    {
                      currentLeague && currentLeague.subleagues &&
                      [...currentLeague.subleagues].sort((a, b) => a.display_name !== b.display_name ? a.display_name < b.display_name ? -1 : 1 : 0).map((league, index) => {
                        return <div key={ index } className="prize-subleague">
                          <div className="subleague-heading">
                            <span className={ 'subleague-name subleague-'+league.display_name.toLowerCase() }>{ league.display_name } League</span>
                            <span className="subleague-regions">{ currentLeague.subleagues.length } Region{ (currentLeague.subleagues.length > 1) ? 's' : '' }</span>
                          </div>
                          <div className="prize-payouts">
                            {
                              [...settings.goleague.prizepool.payouts].map((payout, i) => {
                                return <div key={ i } className="prize-payout">
                                  <span className="prize-placement">{ i+1 }</span>
                                  <span className="prize-money">${ parseFloat((((currentLeague.pool*(1-settings.goleague.prizepool.fees))*(league.share/100))*payout)/currentLeague.regions.length).toFixed(2) }</span>
                                </div>
                              })
                            }
                            <div className="prize-cta-container">
                              {
                                (league.entry === 'qualify') &&
                                <div className="prize-cta">Qualify Now <span>!Qualify</span></div>
                              }
                              {
                                (league.entry === 'premium') &&
                                <div className="prize-cta">Join Free <span>!GO</span></div>
                              }
                              {
                                (league.entry === 'invite') &&
                                <div className="prize-cta">Invite Only</div>
                              }
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          {/* <iframe src="https://clips.twitch.tv/embed?clip=ProudGentleHerdTTours-Vdfj18tU4D74wKws&parent=localhost" width="1280" height="720" allowfullscreen="false" autoplay="true"></iframe> */}
        </div>
      </>
    );
  };
}


const mapStateToProps = (state, ownProps) => {
  let overlay = {};
  if (state.firestore.data.overlays && ownProps.match.params.id && state.firestore.data.overlays[ownProps.match.params.id]) {
    overlay = state.firestore.data.overlays[ownProps.match.params.id];
  }
  let zipcode = '1234';
  if (state.firestore.data.overlays && ownProps.match.params.id && state.firestore.data.overlays[ownProps.match.params.id] && state.firestore.data.overlays[ownProps.match.params.id].weather && state.firestore.data.overlays[ownProps.match.params.id].weather.zipcode) {
    zipcode = state.firestore.data.overlays[ownProps.match.params.id].weather.zipcode;
  }
  let weather = {};
  if (state.firestore.data.weather && state.firestore.data.weather[zipcode]) {
    weather = state.firestore.data.weather[zipcode];
  }
  let catchOwners = ['1234'];
  if (overlay && overlay.owner) {
    catchOwners.push(overlay.owner);
  }
  if (overlay && overlay.multi) {
    for (var prop in overlay.multi) {
      if (overlay.multi.hasOwnProperty(prop)) {
        if (overlay.multi[prop]._id !== overlay.owner) {
          catchOwners.push(overlay.multi[prop]._id);
        }
      }
    }
  }
  return {
    overlay : overlay,
    zipcode : zipcode,
    weather : weather,
    catches : state.firestore.data.catches,
    settings : state.firestore.data.settings,
    rankings : state.firestore.data.rankings,
    catchOwners : catchOwners
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetWeatherShown: (id) => dispatch(resetWeatherShown(id)),
    resetRankingsShown: (id) => dispatch(resetRankingsShown(id)),
    resetBagShown: (id) => dispatch(resetBagShown(id)),
    resetCatchesShown: (id) => dispatch(resetCatchesShown(id)),
    resetRefresh: (id, callback) => dispatch(resetRefresh(id, callback)),
    resetPrizeShown: (id) => dispatch(resetPrizeShown(id)),
    getProfile: (id, callback) => dispatch(getProfile(id, callback))
  }
};

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'overlays', doc : props.match.params.id },
    { collection: 'weather', doc : props.zipcode },
    { collection: 'settings', doc : 'goleague' },
    { collection: 'rankings', doc : 'goleague' },
    { collection: 'catches', 
      where: [
        ['owner', 'in', props.catchOwners ]
      ]
    }
  ])
)(Overlay))