export const decycle = (obj, stack = []) => {
  if (!obj || typeof obj !== 'object')
      return obj;
  
  if (stack.includes(obj))
      return null;

  let s = stack.concat([obj]);

  return Array.isArray(obj)
      ? obj.map(x => decycle(x, s))
      : Object.fromEntries(
          Object.entries(obj)
              .map(([k, v]) => [k, decycle(v, s)]));
}

export const removeProp = (obj, propToDelete) => {
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] == "object") {
        removeProp(obj[property], propToDelete);
      } else {
        if (property === propToDelete && obj[property] === true) {
          delete obj[property];
        }
      }
    }
  }
}

export const objToArray = (object1) => {
  let arr = [];
  for (const [key, value] of Object.entries(object1)) {
    let obj = Object.assign({}, value);
    obj._id = key;
    arr.push(obj);
  }
  return arr;
}