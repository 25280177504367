import { toast } from "react-toastify";

const authReducer = (state = {}, action) => {
  switch (action.type) {
    case "SIGN_IN":
      toast("Welcome!");
      return { ...state,
        signInSuccess : true,
        signOutSuccess : null
      };
    case "SIGN_IN_ERR":
      toast.error("Sign in error...");
      return state;
    case "SIGN_OUT":
      toast("You signed out..");
      return { ...state,
        signInSuccess : null,
        signOutSuccess : true
      };
    default:
      return state; 
  }
};

export default authReducer;
