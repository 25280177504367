import React, { Component } from "react";
import autoBind from 'react-autobind';
import Foot from "../layout/Foot";
import RulesText from '../terms/RulesText';

class Rules extends Component {

  constructor(props){
    super(props);
    this.state = {
      
    }
    autoBind(this);
  }

  render(){
    const { uid } = this.props;
    return (
      <>
        <section className="hero hero-sm">
          <div className="container">
            <h1>League Rules</h1>
            <h2>Review the League Rules</h2>
          </div>
          <div className="hero-water"></div>
        </section>
        <section>
          <div className="container">
            <h4>GO League Rules</h4>
            <RulesText />
          </div>
        </section>
        <Foot />
      </>
    );
  };
}

export default Rules