import React, { Component } from "react";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import { objToArray } from '../../utils/Object';
import autoBind from 'react-autobind';
import Foot from "../layout/Foot";

class Mod extends Component {

  constructor(props){
    super(props);
    this.state = {
      
    }
    autoBind(this);
  }

  componentDidUpdate(){
    
  }

  render(){
    const { uid, stripeRole, modFor, overlays, usernames } = this.props;
    if (!uid) return <Redirect to="/" />;
    const isPremium = () => {
      return (stripeRole === 'premium');
    };
    return (
      <>
        <section className="hero hero-sm">
          <div className="container">
            <h1>Moderation</h1>
            <h2>Update settings for channels you moderate</h2>
          </div>
          <div className="hero-water"></div>
        </section>
        <section>
          <div className="container">
            {
              (usernames && modFor && overlays && objToArray(overlays).length > 0) ?
              <>
                <div className="alert-msg alert-info">
                  <span className="material-icons">colorize</span>
                  <span className="alert-msg-text">As a moderator, you have access to moderate these pages for free. You do not have to pay the subscription fee to be a moderator, only if you wish to use the service on your own livestream.</span>
                </div>
                <h3>Your Moderated Channels:</h3>
                <ul className="mod-list">
                  {
                    objToArray(overlays).map(overlay => {
                      return <>
                        {
                          (usernames[overlay.owner] && modFor.includes(overlay.owner)) &&
                          <div key={ overlay._id } className="mod-for">
                            <h4>{ (usernames[overlay.owner] && usernames[overlay.owner].display_name) ? usernames[overlay.owner].display_name : overlay.owner }</h4>
                            <ul className="mod-list">
                              <li><Link to={ '/settings/'+overlay._id }>Overlay Settings</Link></li>
                              <li><Link to={ '/catch/'+overlay._id }>Fish Catch Log</Link></li>
                            </ul>
                          </div>
                        }
                      </>
                    })
                  }
                </ul>
              </>
              :
              <h4>Page not found.</h4>
            }
          </div>
        </section>
        <Foot />
      </>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const uid = state.firebase.auth.uid;
  const modFor = (state.firestore.data.modFor && state.firestore.data.modFor[state.firebase.auth.uid] && state.firestore.data.modFor[state.firebase.auth.uid].modFor) ? state.firestore.data.modFor[state.firebase.auth.uid].modFor : [state.firebase.auth.uid];
  return {
    uid : uid,
    modFor : modFor,
    overlays : state.firestore.data.overlays,
    usernames : state.firestore.data.usernames
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};
export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => {
    return [{
      collection: 'modFor', doc : props.uid },
    { collection: 'overlays' },
    { collection: 'usernames' }
  ];
})
)(Mod))