import React, { Component } from "react";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import autoBind from 'react-autobind';
import TimeAgo from 'react-timeago';

class History extends Component {

  constructor(props){
    super(props);
    this.state = {
      
    }
    autoBind(this);
  }


  render(){
    const { uid, stripeRole, usernames, admin, catchesA, species, goleague } = this.props;
    if (!uid) return <Redirect to="/" />;
    if (!admin || typeof admin.global !== 'number' || admin.global > 2) return <Redirect to="/" />;
    const isPremium = () => {
      return (stripeRole === 'premium');
    };
    
    return (
      <div className="catch-admin-history">
        <h4 data-scrollto="catch-list">Catches History</h4>
        {
          (usernames) &&
          catchesA.filter(fish => typeof fish.species !== 'undefined' && typeof fish.updatedAt !== 'undefined').sort((a, b) => b.updatedAt.toDate() - a.updatedAt.toDate()).map(fish => {
            return <div className="caught-fish admin-fish" key={fish._id}>
            <div className={ 'admin-by' + ((fish.rejected === true) ? ' admin-rejected' : '') + ((fish.verified === true) ? ' admin-approved' : '') }>
                <span>
                  { usernames[fish.adminBy].display_name }
                  {
                    (fish.rejected === true) &&
                    ' rejected '
                  }
                  {
                    (fish.verified === true) &&
                    ' approved '
                  }
                  this fish <TimeAgo date={ fish.updatedAt.toDate() } />.
                </span>
              </div>
              <div className="caught-fish-inner">
                <div className="username">{ usernames[fish.owner].display_name }</div>
                <div className="fish-image">
                  <img src={ (species[fish.species]) ? species[fish.species].img : '/img/species-unknown.png'  } />
                  <div className="fish-name">
                    <span>{ (species[fish.species]) ? species[fish.species].display_name : fish.species }</span>
                  </div>
                </div>
                <div className="fish-weight">
                  <span>{ (fish.weight > 0) ? fish.weight+' lbs' : 'Unknown' } </span>
                  <span className="fish-length">{ (fish.length && fish.length > 0) ? parseFloat(fish.length)+' in' : 'Unknown' }</span>
                </div>
                <div className="fish-datetime">
                  <span><TimeAgo date={ fish.dateTime.toDate() } /></span>
                </div>
                {
                  (fish.askVerify) &&
                  <div className="fish-verified">
                    <span className="material-icons">verified</span><span className="fish-verified-label">Pending</span>
                  </div>
                }
                <div className="fish-buttons">
                  {
                    (fish.clip) &&
                    <a className="btn btn-primary btn-clip" href={ fish.clip } target="_blank"><span className="material-icons">video_library</span></a>
                  }
                </div>
                {
                  (this.state.toggleEdit === fish._id) &&
                  <div className="edit-confirm-container">
                    <span className="edit-confirm">Admin Review of Pending Catch</span>
                    <div className="settings-form">
                      <div className="formline">
                        <label htmlFor="approvedScale">Does the clip show the fish being weighed on a digital scale?</label>
                        <select ref={el => this.approvedScale=el} value={this.state.inputs.approvedScale} onChange={this.handleChange} name="approvedScale">
                          <option value="">Select...</option>
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                      {
                        (this.state.inputs.approvedScale === 'yes') &&
                        <div className="formline">
                          <label htmlFor="showedZero">Did the streamer zero the scale first and show it zero'd?</label>
                          <select ref={el => this.showedZero=el} value={this.state.inputs.showedZero} onChange={this.handleChange} name="showedZero">
                            <option value="">Select...</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                          </select>
                        </div>
                      }
                      {
                        (this.state.inputs.showedZero === 'yes') &&
                        <div className="formline">
                          <label htmlFor="clearWeight">Does the clip clearly show the fish weight?</label>
                          <select ref={el => this.clearWeight=el} value={this.state.inputs.clearWeight} onChange={this.handleChange} name="clearWeight">
                            <option value="">Select...</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                          </select>
                        </div>
                      }
                      {
                        (this.state.inputs.clearWeight === 'yes') &&
                        <div className="formline">
                          <label htmlFor="foulPlay">Are you confident there was no foul play when this fish was weighed?</label>
                          <select ref={el => this.foulPlay=el} value={this.state.inputs.foulPlay} onChange={this.handleChange} name="foulPlay">
                            <option value="">Select...</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                          </select>
                        </div>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          })
        }
      </div>
    );
  };
}


const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid;
  const me = (state.firestore.data.users && state.firestore.data.users[uid]) ? Object.assign({}, state.firestore.data.users[uid]) : {};
  if (me.email) {
    delete me.email;
  }
  let history = [];
  if (state.firestore.data.catchesA) {
    let data = state.firestore.data.catchesA;
    for (let prop in data) {
      if (data.hasOwnProperty(prop)) {
        if (typeof data[prop].adminBy !== 'undefined') {
          history.push({
            _id : prop,
            ...data[prop]
          });
        }
      }
    }
  }
  return {
    uid : uid,
    me : me,
    catchesA : history
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'catches', storeAs : 'catchesA' }
  ])
)(withRouter(History))