import React, { Component } from "react";
import { connect } from "react-redux";
import DateCountdown from "react-date-countdown-timer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Foot from "../layout/Foot";
var Carousel = require("react-responsive-carousel").Carousel;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carousel: 0,
    };
  }

  render() {
    const { uid, stripeRole } = this.props;
    const isPremium = () => {
      return stripeRole === "premium";
    };
    const onChange = (i) => {
      this.setState({ carousel: i });
    };
    return (
      <>
        <section className="hero">
          <div className="container">
            <h1>Virtual Fishing League</h1>
            <h2>Compete LIVE for cash from your own secret honey holes</h2>
            <div className="hero-counter">
              <div className="counter">
                <DateCountdown
                  dateTo="July 15, 2023 00:00:00 UTC-05:00"
                  locales={["Y", "M", "D", "H", "M", "S"]}
                  locales_plural={["Y", "M", "D", "H", "M", "S"]}
                  mostSignificantFigure="day"
                />
                <div className="timer-label">Bass Season 7 Starts</div>
              </div>
              <a
                href={
                  window.location.hostname === "localhost"
                    ? "http://localhost:5001/go-league/us-central1/redirect"
                    : "https://us-central1-go-league.cloudfunctions.net/redirect"
                }
                className="btn btn-primary"
              >
                Get Started
              </a>
              <a
                href="https://discord.gg/wrYkxsWv9k"
                target="_blank"
                className="btn btn-primary btn-discord"
              >
                Join Discussion
              </a>
            </div>
          </div>
          <div className="hero-water"></div>
        </section>
        <section className="about" data-scrollto="benefits">
          <div className="container">
            <h3>Livestream Fishing with GO League</h3>
            <p>
              Utilize the GO League streaming overlays and compete in virtual
              fishing leagues for cash prize pools and cash bounties &mdash; all
              while adding exciting content and interactivity to your
              livestreams. Allow your viewers to trigger overlays using chat
              commands to checking your fish caught, league rank, and even local
              weather. Sponsors and community supporters can trigger bounties at
              any time that GO League participants can race to complete for cash
              prices.
            </p>
          </div>
        </section>
        <section className="phone">
          <div className="container">
            <Carousel
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              autoPlay={true}
              infiniteLoop={true}
              interval={5000}
              onChange={(i) => onChange(i)}
            >
              <div>
                <img src="/img/overlay-1.png" />
              </div>
              <div>
                <img src="/img/overlay-2.png" />
              </div>
              <div>
                <img src="/img/overlay-3.png" />
              </div>
              <div>
                <img src="/img/overlay-4.png" />
              </div>
              <div>
                <img src="/img/overlay-5.png" />
              </div>
              <div>
                <img src="/img/overlay-6.png" />
              </div>
              <div>
                <img src="/img/overlay-7.png" />
              </div>
            </Carousel>
            <div className="carousel-labels">
              {this.state.carousel === 0 && (
                <div className="carousel-label">
                  <h4>REAL-TIME COMPETITOR OVERLAYS</h4>
                  <p>
                    As a member of GO League, you'll have access to professional
                    streaming overlays that display all of your real-time league
                    and fishing information on your livestream.
                  </p>
                </div>
              )}
              {this.state.carousel === 1 && (
                <div className="carousel-label">
                  <h4>COMMUNITY FUNDED PRIZE POOL</h4>
                  <p>
                    Compete for a portion of the prize pool that is funded by
                    the community and sponsors. Allow your communities to
                    contribute to the prize pool and help grow the virtual
                    competitive fishing community.{" "}
                  </p>
                </div>
              )}
              {this.state.carousel === 2 && (
                <div className="carousel-label">
                  <h4>Real-Time Weather Widget</h4>
                  <p>
                    Utilize interactive widgets in your livestream like live
                    local weather information. Allow viewers of your livestream
                    to trigger the overlays using chat commands.
                  </p>
                </div>
              )}
              {this.state.carousel === 3 && (
                <div className="carousel-label">
                  <h4>Log Everything You Catch</h4>
                  <p>
                    Track and keep record of every fish you catch and allow your
                    community to view your fishing records at any time. View
                    your current league bag weight and league ranking in
                    real-time.
                  </p>
                </div>
              )}
              {this.state.carousel === 4 && (
                <div className="carousel-label">
                  <h4>Check Real-Time League Rankings</h4>
                  <p>
                    Allow viewers of your livestream to check league rankings
                    and where you stand in the rankings in real-time.
                  </p>
                </div>
              )}
              {this.state.carousel === 5 && (
                <div className="carousel-label">
                  <h4>Sponsor and Activate Cash Bounties</h4>
                  <p>
                    Sponsored cash bounties will activate in real-time at
                    anytime throughout the league providing exciting new content
                    for your livestream. Allow viewers of your livestream to
                    activate cash bounties for league participants.{" "}
                  </p>
                </div>
              )}
              {this.state.carousel === 6 && (
                <div className="carousel-label">
                  <h4>Compete for Cash Bounties</h4>
                  <p>
                    Compete for cash bounties that are activated in real-time by
                    sponsors and viewers of any of the GO League participant's
                    communities.
                  </p>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="hero-2" data-scrollto="donors">
          <div className="container">
            <h3>Support Your Favorite Creators</h3>
            <p>
              Contribute to the prize pool or activate cash bounties for your
              favorite content creators to complete for. Prepay subscription
              fees on behalf of your favorite content creators. If you don't see
              your favorite creator using GO League, make sure to send them
              here!
            </p>
            <div className="two-up">
              <div className="up">
                <div className="two-up-inner">
                  <h4>Prize Pool</h4>
                  <p>
                    Contributing to the prize pool helps grow the virtual
                    competitive fishing community and helps support the content
                    creators you know and love. Consider donating today!
                  </p>
                </div>
              </div>
              <div className="up">
                <div className="two-up-inner">
                  <h4>Fishing Bounties</h4>
                  <p>
                    Activate exciting real-time cash fishing bounties and watch
                    your favorite content creators scramble to complete the
                    challenge and secure the cash prize.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sponsor" data-scrollto="sponsors">
          <div className="container">
            <h3>Become a GO League Sponsor</h3>
            <p>
              Sponsoring GO League supports a new generation of fishing without
              boundaries and powered by technology. More information will become
              available soon on becoming a sponsor.
            </p>
          </div>
        </section>
        <section className="pricing" data-scrollto="pricing">
          <div className="container">
            <h3>GO League Pricing</h3>
            <p>
              Join GO League today and gain instant access to streaming
              overlays, compete for cash prizes and work your way up to the
              coveted Elite League!
            </p>
            <div className="price-compare">
              <div className="compare">
                <div className="price-header">
                  <h4>&nbsp;</h4>
                </div>
                <div className="price-content">
                  <span>Pricing</span>
                  <span>Stream Overlays</span>
                  <span>League Competitors</span>
                  <span>Prize Pool Share</span>
                  <span>League Advancement</span>
                </div>
              </div>
              <div className="compare open">
                <div className="price-header">
                  <h4>Open League</h4>
                </div>
                <div className="price-content">
                  <span>$7.99/MO*</span>
                  <span>Included*</span>
                  <span>Unlimited*</span>
                  <span>40%*</span>
                  <span>Top 2 Move Up*</span>
                  <a
                    href={
                      window.location.hostname === "localhost"
                        ? "http://localhost:5001/go-league/us-central1/redirect"
                        : "https://us-central1-go-league.cloudfunctions.net/redirect"
                    }
                    className="btn btn-primary"
                  >
                    Get Started
                  </a>
                </div>
              </div>
              <div className="compare elite">
                <div className="price-header">
                  <h4>Elite League</h4>
                </div>
                <div className="price-content">
                  <span>Qualify/Invite Only*</span>
                  <span>Included*</span>
                  <span>20 Max Per Division*</span>
                  <span>60%*</span>
                  <span>Bottom 2 Move Down*</span>
                </div>
              </div>
            </div>
            <p className="disclaimer">
              *Exact details are still being finalized and are subject to change
              depending on participation and community feedback.
            </p>
          </div>
        </section>
        <Foot />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid;
  return {
    uid: uid,
  };
};

export default connect(mapStateToProps)(Home);
