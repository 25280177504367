import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import joinReducer from "./joinReducer";
import overlayReducer from "./overlayReducer";
import authReducer from "./authReducer";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  join: joinReducer,
  overlay: overlayReducer,
  auth: authReducer
});

export default rootReducer;
