import React, { Component } from "react";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import { objToArray } from '../../utils/Object';
import autoBind from 'react-autobind';
import Foot from "../layout/Foot";

class Admin extends Component {

  constructor(props){
    super(props);
    this.state = {
      
    }
    autoBind(this);
  }

  render(){
    const { uid, stripeRole, users, admin, catches } = this.props;
    if (!uid) return <Redirect to="/" />;
    if (!admin || typeof admin.global !== 'number') return <Redirect to="/" />;
    const isPremium = () => {
      return (stripeRole === 'premium');
    };
    
    return (
      <>
        <section className="hero hero-sm">
          <div className="container">
            <h1>Administration</h1>
            <h2>GO League Administration Panel</h2>
          </div>
          <div className="hero-water"></div>
        </section>
        <section>
          <div className="container">
            {
              (admin.global <= 2) &&
              <div className="pending-catches">
                <Link to="/admin/catches">
                  <span className="admin-text">
                    Pending GO League Catches
                    {
                      (typeof catches !== 'undefined') &&
                      <span className={ 'pending-count'+((objToArray(catches).length === 0) ? ' pending-zero' : '' ) }>{ objToArray(catches).length }</span>
                    }
                  </span>
                  <span className="btn btn-primary">Manage</span>
                </Link>
              </div>
            }
            {
              (admin.global === 1) &&
              <p>
                <Link to="/admin/waitlist" className="nav-link">
                  View Waitlist Admin
                </Link>
              </p>
            }
          </div>
        </section>
        <Foot />
      </>
    );
  };
}


const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid;
  const me = (state.firestore.data.users && state.firestore.data.users[uid]) ? Object.assign({}, state.firestore.data.users[uid]) : {};
  if (me.email) {
    delete me.email;
  }
  return {
    users : state.firestore.data.users,
    uid : uid,
    me : me,
    goleague : (state.firestore.data.settings && state.firestore.data.settings.goleague) ? state.firestore.data.settings.goleague : {},
    species : (state.firestore.data.settings && state.firestore.data.settings.goleague && state.firestore.data.settings.goleague.species) ? state.firestore.data.settings.goleague.species : {},
    catches : (state.firestore.data.catches) ? state.firestore.data.catches : {}
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'users' },
    { collection: 'settings', doc : 'goleague' },
    { collection: 'catches', 
      where: [
        ['askVerify', '==', true ],
        ['locked', '==', true ],
        ['verified', '!=', true ]
      ]
    }
  ])
)(withRouter(Admin))