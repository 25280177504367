import { toast } from "react-toastify";

const taskReducer = (state = {}, action) => {
  switch (action.type) {
    case "JOIN_WAITLIST_SUCCESS": {
      toast.success("Successfully joined the waitlist");
      return state;
    }
    case "UPDATE_WAITLIST_SUCCESS": {
      toast.success("Successfully updated settings");
      return state;
    }
    case "LEAVE_WAITLIST_SUCCESS": {
      toast.success("Successfully left the waitlist");
      return state;
    }
    case "JOIN_WAITLIST_ERROR": {
      toast.error("An error occurred");
      return state;
    }
    case "LEAVE_WAITLIST_ERROR": {
      toast.error("An error occurred");
      return state;
    }
    default:
      return state;
  }
};

export default taskReducer;
