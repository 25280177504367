import React, { Component} from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SignIn from "./components/auth/SignIn";
import Home from "./components/home/Home";
import Join from "./components/join/Join";
import Settings from "./components/settings/Settings";
import Catches from "./components/catches/Catches";
import Mod from "./components/mod/Mod";
import Overlay from "./components/overlay/Overlay";
import WaitlistAdmin from "./components/join/Admin";
import CatchesAdmin from "./components/catches/Admin";
import Admin from "./components/admin/Admin";
import Rankings from "./components/rankings/Rankings";
import Terms from "./components/terms/Terms";
import Rules from "./components/terms/Rules";
import Privacy from "./components/terms/Privacy";
import NavBar from "./components/layout/NavBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "./config/firebaseConfig";

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      stripeRole: {},
    }
  }

  componentWillUnmount() {
    if (this.authListener) {
      this.authListener();
    }
  }

  componentDidMount() {
    this.authListener = firebase.auth().onAuthStateChanged(user => {
      if (!user) {
        this.setState({
          stripeRole : 'free',
          beta : false
        });
      } else {
        firebase.auth().currentUser.getIdToken(true).then(() => {
          firebase.auth().currentUser.getIdTokenResult().then((decodedToken) => {
            this.setState({
              stripeRole : (decodedToken.claims.stripeRole === 'premium') ? 'premium' : 'free',
              admin : decodedToken.claims.admin,
              beta : decodedToken.claims.beta || false,
              modFor : decodedToken.claims.modFor || [],
              freeTrial : decodedToken.claims.freeTrial || false
            });
          });
        });
      }
    });
  }

  render() {
    const isMod = () => {
      if (this.state.modFor && this.state.modFor.length > 0) {
        return true;
      }
      return false;
    };
    return (
      <>
        <BrowserRouter>
          <ToastContainer />
          <NavBar stripeRole={ this.state.stripeRole } admin={ this.state.admin } beta={ this.state.beta } mod={isMod()} freeTrial={ this.state.freeTrial } />
            {
              (this.state.beta !== undefined) && 
              <Switch>
                <Route path="/signin" component={SignIn} />
                <Route path="/join" render={()=> <Join stripeRole={ this.state.stripeRole } />} />
                <Route path="/catch/:id" render={()=> <Catches stripeRole={ this.state.stripeRole } beta={ this.state.beta } mod={isMod()} modFor={this.state.modFor} freeTrial={ this.state.freeTrial } />} />
                <Route path="/catch" render={()=> <Catches stripeRole={ this.state.stripeRole } beta={ this.state.beta } freeTrial={ this.state.freeTrial } />} />
                <Route path="/settings/:id" render={()=> <Settings stripeRole={ this.state.stripeRole } beta={ this.state.beta } mod={isMod()} modFor={this.state.modFor} freeTrial={ this.state.freeTrial } />} />
                <Route path="/settings" render={()=> <Settings stripeRole={ this.state.stripeRole } beta={ this.state.beta } freeTrial={ this.state.freeTrial } />} />
                <Route path="/mod" render={()=> <Mod stripeRole={ this.state.stripeRole } />} />
                <Route path="/overlay/:id" render={()=> <Overlay stripeRole={ this.state.stripeRole } />} />
                <Route path="/admin/waitlist" render={()=> <WaitlistAdmin stripeRole={ this.state.stripeRole } admin={ this.state.admin } />} />
                <Route path="/admin/catches" render={()=> <CatchesAdmin stripeRole={ this.state.stripeRole } admin={ this.state.admin } />} />
                <Route path="/admin" render={()=> <Admin stripeRole={ this.state.stripeRole } admin={ this.state.admin } />} />
                <Route path="/rankings/:id/:season/:region" render={()=> <Rankings stripeRole={ this.state.stripeRole } admin={ this.state.admin } />} />
                <Route path="/rankings/:id/:season" render={()=> <Rankings stripeRole={ this.state.stripeRole } admin={ this.state.admin } />} />
                <Route path="/rankings/:region" render={()=> <Rankings stripeRole={ this.state.stripeRole } admin={ this.state.admin } />} />
                <Route path="/rankings" render={()=> <Rankings stripeRole={ this.state.stripeRole } admin={ this.state.admin } />} />
                <Route path="/terms" render={()=> <Terms />} />
                <Route path="/rules" render={()=> <Rules />} />
                <Route path="/privacy" render={()=> <Privacy />} />
                <Route path="/" render={()=> <Home stripeRole={ this.state.stripeRole } />} exact />
              </Switch>
            }
        </BrowserRouter>
      </>
    );
  }
}

export default App;
