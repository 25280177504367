import React, { Component } from "react";
import { signInTwitch } from "../../actions/authActions";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import queryString from 'query-string';

class SignIn extends Component {
  state = {
    email: "",
    password: "",
  };

  componentDidMount() {
    const q = queryString.parse(this.props.location.search);
    if (q.token) {
      this.props.signInTwitch(q.token);
    }
  }

  render() {
    const { uid, auth } = this.props;
    if (auth.signInSuccess === true && auth.signOutSuccess !== true && uid) return <Redirect to="/settings" />
    if (auth.signInSuccess !== true && auth.signOutSuccess === true && typeof uid === 'undefined') return <Redirect to="/" />
    return (
      <div className="container">
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
const uid = state.firebase.auth.uid;
  return {
    auth : state.auth,
    uid: uid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signInTwitch: (token) => dispatch(signInTwitch(token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
