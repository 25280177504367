import React, { Component } from "react";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import { objToArray } from "../../utils/Object";
import autoBind from "react-autobind";
import Foot from "../layout/Foot";
import DateCountdown from "react-date-countdown-timer";
import TimeAgo from "react-timeago";

class Rankings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: 0,
      toggleFish: {},
    };
    autoBind(this);
  }

  componentDidUpdate() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      let id = this.props.match.params.id;
      let season = this.props.match.params.season;
      let region = this.props.match.params.region;
      if (
        typeof region !== "undefined" &&
        this.props.goleague &&
        this.props.goleague.regions
      ) {
        this.props.goleague.regions.forEach((reg, ri) => {
          if (reg.display_name.toLowerCase() === region.toLowerCase()) {
            if (this.state.sort !== ri) {
              this.setState({
                sort: ri,
              });
            }
          }
        });
      }
      if (
        id &&
        season &&
        typeof region === "undefined" &&
        this.props.goleague &&
        this.props.goleague.leagues
      ) {
        this.props.goleague.leagues.forEach((league, li) => {
          if (id === league.id && parseInt(season, 10) === league.season) {
            this.props.history.push(
              this.props.location.pathname +
                "/" +
                this.props.goleague.regions[
                  league.regions[0]
                ].display_name.toLowerCase()
            );
          }
        });
      }
    } else {
      let region = this.props.match.params.region;
      if (
        typeof region !== "undefined" &&
        this.props.goleague &&
        this.props.goleague.regions
      ) {
        this.props.goleague.regions.forEach((reg, ri) => {
          if (reg.display_name.toLowerCase() === region.toLowerCase()) {
            if (this.state.sort !== ri) {
              this.setState({
                sort: ri,
              });
            }
          }
        });
      }
    }
  }

  sortRanks(sort) {
    this.setState({
      sort: sort,
    });
  }

  expandFish(id) {
    let data = {};
    data[id] = !this.state.toggleFish[id];
    this.setState({
      toggleFish: {
        ...this.state.toggleFish,
        ...data,
      },
    });
  }

  render() {
    const { goleague, rankings, streams, overlays, match } = this.props;
    let species = goleague && goleague.species ? goleague.species : {};
    let leagues = [];
    if (
      goleague.leagues &&
      Object.keys(rankings).length === goleague.leagues.length
    ) {
      goleague.leagues.forEach((league, li) => {
        if (league.starts.toDate() < new Date()) {
          leagues.push(Object.assign({}, league));
          leagues[leagues.length - 1].rankings = [];
          if (match && match.params) {
            if (
              (typeof match.params.id === "undefined" ||
                typeof match.params.season === "undefined") &&
              typeof match.params.region === "undefined"
            ) {
              if (
                league.archived !== true &&
                this.state.sort !== league.regions[0]
              ) {
                this.sortRanks(league.regions[0]);
              }
            }
          }
          if (
            rankings[`${league.id}-${league.season}`] &&
            rankings[`${league.id}-${league.season}`].subleagues
          ) {
            rankings[`${league.id}-${league.season}`].subleagues.map(
              (subleague, si) => {
                subleague.participants.forEach((user) => {
                  let userData = Object.assign({}, user);
                  userData.region =
                    league.subleagues[si].participants[user._id];
                  userData.subleague = league.subleagues[si].id;
                  leagues[leagues.length - 1].rankings.push(userData);
                });
              }
            );
          }
          leagues[leagues.length - 1].rankings.sort((a, b) =>
            a.display_name !== b.display_name
              ? a.display_name < b.display_name
                ? -1
                : 1
              : 0
          );
        }
      });
    }
    const getPrize = (place, league, season, subleague) => {
      let li = leagues.filter((l) => l.id === league && l.season === season)[0];
      let si = li.subleagues.findIndex((x) => x.id === subleague);
      return parseFloat(
        ((li.pool *
          (li.subleagues[si].share / 100) *
          goleague.prizepool.payouts[place]) /
          li.regions.length) *
          (1 - goleague.prizepool.fees)
      ).toFixed(2);
    };
    const getRank = (uid, league, flag) => {
      let arr = [];
      let rank = 0;
      let leg = [...league].sort((a, b) => b.bagWeight - a.bagWeight);
      if (leg.length === 0) {
        return false;
      }
      let offset = 0;
      leg.forEach((user, index) => {
        let ranked = Object.assign({}, user);
        if (
          leg[index + 1] &&
          typeof leg[index + 1].bagWeight !== "undefined" &&
          leg[index].bagWeight !== leg[index + 1].bagWeight
        ) {
          if (offset > 0) {
            rank += offset;
            offset = 0;
          }
          rank += 1;
        } else {
          if (offset === 0) {
            rank += 1;
          }
          if (leg[index + 1]) {
            offset += 1;
          }
        }
        if (flag === 1) {
          ranked.rank = rank;
        } else if (flag === 2) {
          ranked.rank = offset > 0 ? true : false;
        } else {
          ranked.rank = rank + (offset > 0 ? " (T)" : "");
        }
        arr.push(ranked);
      });
      let myRank = arr.filter((user) => {
        return user._id === uid;
      });
      if (myRank[0] && typeof myRank[0].rank !== "undefined") {
        return myRank[0].rank;
      } else {
        return null;
      }
    };
    let legs = [...leagues];
    let activeLeague = [...legs].slice(-1);
    let pastLeagues = [...legs].splice(0, [...leagues].length - 1).reverse();
    let subrank = false;
    if (match && match.params) {
      if (match.params.id && match.params.season) {
        legs.map((league, li) => {
          if (
            league.season === parseInt(match.params.season, 10) &&
            league.id === match.params.id
          ) {
            activeLeague = [league];
            subrank = true;
          }
        });
      }
    }
    return (
      <>
        <section className="hero hero-sm">
          <div className="container">
            <h1>Rankings</h1>
            <h2>View the current rankings</h2>
          </div>
          <div className="hero-water"></div>
        </section>
        <section>
          <div className="container">
            {!subrank ? (
              <h3>Current Active League</h3>
            ) : (
              <div className="back-rankings">
                <Link to={"/rankings"}>
                  <span>&lt; Back to Rankings Page</span>
                </Link>
              </div>
            )}
            <div className="current-league">
              {legs.length > 0 &&
                [...activeLeague].map((league, li) => {
                  return (
                    <div key={li}>
                      <h4>
                        {league.display_name} League - Season {league.season}{" "}
                        {league.archived && "(Final Results)"}
                      </h4>
                      <p>
                        Join now and compete in leagues or donate to the global
                        prize pool and support all of your favorite fishing
                        streamers!
                      </p>
                      <div className="ranking-tabs">
                        {[...league.regions].length > 1 && (
                          <div className="ranking-tabs-container">
                            {[...league.regions].map((region, ri) => {
                              return (
                                <Link
                                  to={
                                    "/rankings/" +
                                    (match.params.id && match.params.season
                                      ? match.params.id +
                                        "/" +
                                        match.params.season +
                                        "/"
                                      : "") +
                                    goleague.regions[
                                      region
                                    ].display_name.toLowerCase()
                                  }
                                  className={
                                    "ranking-tab" +
                                    (this.state.sort === region
                                      ? " active"
                                      : "")
                                  }
                                  key={ri}
                                >
                                  <span>
                                    {goleague.regions[region].display_name}
                                  </span>
                                </Link>
                              );
                            })}
                          </div>
                        )}
                        <div className="ranking-container">
                          {[...league.subleagues]
                            .sort((a, b) => a.id.localeCompare(b.id))
                            .map((subleague, si) => {
                              return (
                                <div key={si}>
                                  <div className="subleague-bar">
                                    <span>{subleague.display_name}</span>
                                  </div>
                                  {[...league.rankings]
                                    .filter(
                                      (user) =>
                                        user.region == this.state.sort &&
                                        user.subleague === subleague.id &&
                                        overlays[user._id] === true
                                    )
                                    .sort((a, b) => b.bagWeight - a.bagWeight)
                                    .map((user, ui) => {
                                      return (
                                        <div
                                          className={
                                            "rank-user" +
                                            (ui < 3 ? " in-prize" : "")
                                          }
                                          key={ui}
                                        >
                                          <div className="rank-user-inner">
                                            <div className="rank-place">
                                              #
                                              {getRank(
                                                user._id,
                                                [...league.rankings]
                                                  .filter(
                                                    (user) =>
                                                      user.region ===
                                                        this.state.sort &&
                                                      user.subleague ===
                                                        subleague.id &&
                                                      overlays[user._id] ===
                                                        true
                                                  )
                                                  .sort(
                                                    (a, b) =>
                                                      b.bagWeight - a.bagWeight
                                                  )
                                              )}
                                            </div>
                                            <div className="rank-prize">
                                              {ui < 3 ? (
                                                <div>
                                                  <span>Est. Prize</span>
                                                  <span className="prize-text">
                                                    $
                                                    {getPrize(
                                                      ui,
                                                      league.id,
                                                      league.season,
                                                      user.subleague
                                                    )}
                                                  </span>
                                                </div>
                                              ) : (
                                                <div>
                                                  <span>Est. Prize</span>
                                                  <span className="prize-text">
                                                    ---
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div className="rank-person">
                                              <div className="rank-photo">
                                                <img src={user.league_photo} />
                                              </div>
                                              <div className="rank-username">
                                                <a
                                                  href={
                                                    "https://www.twitch.tv/" +
                                                    user.display_name.toLowerCase()
                                                  }
                                                  target="_blank"
                                                >
                                                  <span>
                                                    {user.display_name}
                                                  </span>
                                                  <img src="/img/icon-rank-twitch.png" />
                                                </a>
                                                {streams[user._id] && (
                                                  <div className="rank-live">
                                                    <span className="rank-live-icon blink_me"></span>
                                                    <span className="rank-live-text blink_me">
                                                      LIVE
                                                    </span>
                                                    <span className="viewer-count">
                                                      👁️{" "}
                                                      {
                                                        streams[user._id]
                                                          .viewer_count
                                                      }
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <div className="rank-bag">
                                              <span>{user.bagWeight} lbs</span>
                                            </div>
                                            <div className="rank-fish">
                                              <span>
                                                {user.bag.length} /{" "}
                                                {league.maxBag}
                                              </span>
                                              {user.bag.length > 0 && (
                                                <span
                                                  onClick={() =>
                                                    this.expandFish(user._id)
                                                  }
                                                  className={
                                                    "material-icons" +
                                                    (this.state.toggleFish[
                                                      user._id
                                                    ]
                                                      ? " expand-close"
                                                      : "")
                                                  }
                                                >
                                                  expand
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          {this.state.toggleFish[user._id] && (
                                            <div className="rank-fish-expand">
                                              <div className="rank-fish-expand-inner">
                                                <div className="fish-expand-left">
                                                  <div className="catch-list">
                                                    {user.bag
                                                      .filter(
                                                        (fish) =>
                                                          typeof fish.species !==
                                                          "undefined"
                                                      )
                                                      .sort(
                                                        (a, b) =>
                                                          b.weight - a.weight
                                                      )
                                                      .map((fish, index) => {
                                                        return (
                                                          <div
                                                            key={index}
                                                            className="catch-list-item"
                                                          >
                                                            <div className="fish-catch-number">
                                                              <span className="fish-number">
                                                                {index + 1}
                                                              </span>
                                                            </div>
                                                            <div className="fish-catch-species">
                                                              <div className="fish-catch-img">
                                                                <img
                                                                  style={{
                                                                    width:
                                                                      fish.weight *
                                                                        12 +
                                                                      80 +
                                                                      "%",
                                                                    marginLeft:
                                                                      (fish.weight *
                                                                        12 +
                                                                        80 >
                                                                      100
                                                                        ? "-" +
                                                                          (fish.weight *
                                                                            12 +
                                                                            80 -
                                                                            100) /
                                                                            2
                                                                        : "0") +
                                                                      "%",
                                                                  }}
                                                                  src={
                                                                    species[
                                                                      fish
                                                                        .species
                                                                    ]
                                                                      ? species[
                                                                          fish
                                                                            .species
                                                                        ].img
                                                                      : "/img/species-unknown-light.png"
                                                                  }
                                                                />
                                                              </div>
                                                            </div>
                                                            <div className="fish-catch-stats">
                                                              <span className="fish-catch-ago">
                                                                <TimeAgo
                                                                  date={fish.dateTime.toDate()}
                                                                  formatter={(
                                                                    value,
                                                                    unit,
                                                                    suffix
                                                                  ) => {
                                                                    if (
                                                                      unit ===
                                                                      "minute"
                                                                    ) {
                                                                      if (
                                                                        value >
                                                                        1
                                                                      ) {
                                                                        unit =
                                                                          "mins.";
                                                                      } else {
                                                                        unit =
                                                                          "min.";
                                                                      }
                                                                    }
                                                                    return (
                                                                      value +
                                                                      " " +
                                                                      unit +
                                                                      " " +
                                                                      suffix
                                                                    );
                                                                  }}
                                                                />
                                                              </span>
                                                            </div>
                                                            <div className="fish-catch-details">
                                                              <div className="fish-catch-info">
                                                                <span className="fish-catch-info-weight">
                                                                  <span className="fish-catch-info-text">
                                                                    {fish.weight >
                                                                    0
                                                                      ? fish.weight.toFixed(
                                                                          2
                                                                        )
                                                                      : "---"}
                                                                  </span>
                                                                  <span className="fish-catch-info-label">
                                                                    lbs
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <a
                                                              className="btn btn-primary btn-clip"
                                                              href={fish.clip}
                                                              target="_blank"
                                                            >
                                                              <span className="material-icons">
                                                                video_library
                                                              </span>
                                                            </a>
                                                          </div>
                                                        );
                                                      })}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {ui === 2 && (
                                            <div className="rank-divider"></div>
                                          )}
                                          {/* {
                                      ((ui+2) % 4 == 0) &&
                                      <div className="rank-donate"><span>Increase the global prize pool now and support your favorite fishing streamers!</span> <a href="https://streamelements.com/gamersoutdoors/tip" target="_blank" className="btn btn-primary">Donate</a></div>
                                    } */}
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {!subrank && (
              <>
                <h3>Past Leagues</h3>
                <div className="past-league">
                  {legs.length > 0 &&
                    [...pastLeagues].map((league, li) => {
                      return (
                        <div key={li}>
                          <Link
                            to={"/rankings/" + league.id + "/" + league.season}
                          >
                            <span>
                              {league.display_name} League - Season{" "}
                              {league.season} (Final Results)
                            </span>
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </section>
        <Foot />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  let overlays = {};
  if (
    state.firestore.data.overlays &&
    objToArray(state.firestore.data.overlays) &&
    objToArray(state.firestore.data.overlays).length
  ) {
    objToArray(state.firestore.data.overlays).forEach((overlay) => {
      overlays[overlay.owner] = overlay.active === true ? true : false;
    });
  }
  return {
    goleague:
      state.firestore.data.settings && state.firestore.data.settings.goleague
        ? state.firestore.data.settings.goleague
        : {},
    rankings:
      state.firestore.data.rankings && state.firestore.data.rankings
        ? state.firestore.data.rankings
        : {},
    streams: state.firestore.data.streams ? state.firestore.data.streams : {},
    overlays: overlays,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    { collection: "settings", doc: "goleague" },
    { collection: "rankings" },
    { collection: "streams" },
    { collection: "overlays" },
  ])
)(withRouter(Rankings));
