let host = (window.location.hostname === 'localhost') ? 'http://localhost:5001/go-league/us-central1/' : 'https://us-central1-go-league.cloudfunctions.net/';

export const getProfile = (id, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    return fetch(host+'getProfile?id='+id)
    .then(response => response.json())
    .then((jsonData) => {
      if (typeof callback === 'function') {
        callback(jsonData);
      }
    }).catch(err => {
      dispatch({ type: 'GET_PROFILE_ERROR' }, err);
    });
  };
};

export const updateZipcode = (overlayKey, inputs, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    return fetch(host+'getWeather?zip='+inputs.zipcode)
    .then(response => response.json())
    .then((jsonData) => {
      if (jsonData && jsonData.data && jsonData.data.current) {
        firestore.collection('weather').doc(inputs.zipcode).set({
          ...jsonData.data
        }).then((weather) => {
          firestore.collection('overlays').doc(overlayKey).get().then((overlay) => {
            let overlayData = overlay.data();
            overlayData.weather = overlayData.weather || {};
            overlayData.weather.zipcode = inputs.zipcode;
            if (inputs.weatherMode) {
              overlayData.weather.mode = inputs.weatherMode;
            }
            if (inputs.weatherStyle) {
              overlayData.weather.style = inputs.weatherStyle;
            }
            if (inputs.weatherAlign) {
              overlayData.weather.align = inputs.weatherAlign;
            }
            if (inputs.weatherSeconds) {
              overlayData.weather.secs = inputs.weatherSeconds;
            }
            if (inputs.weatherOpacity) {
              overlayData.weather.opacity = inputs.weatherOpacity;
            }
            if (inputs.weatherChatResponse) {
              overlayData.weather.chatResponse = inputs.weatherChatResponse;
            }
            if (inputs.chatViewer) {
              overlayData.weather.chatViewer = inputs.chatViewer;
            }
            if (inputs.weatherChatCooldown) {
              overlayData.weather.chatCooldown = inputs.weatherChatCooldown;
            }
            if (typeof inputs.chatTTSSymbol !== 'undefined') {
              overlayData.weather.chatTTSSymbol = inputs.chatTTSSymbol;
            }
            firestore.collection('overlays').doc(overlayKey).set(overlayData, { merge : true }).then(() => {
              dispatch({ type: 'WEATHER_SETTINGS_SUCCESS' });
              if (typeof callback === 'function') {
                callback();
              }
            }).catch(err => {
              dispatch({ type: 'WEATHER_SETTINGS_ERROR' }, err);
            });
          }).catch(err => {
            dispatch({ type: 'WEATHER_SETTINGS_ERROR' }, err);
          });
        }).catch(err => {
          dispatch({ type: 'WEATHER_SETTINGS_ERROR' }, err);
        });
      } else {
        dispatch({ type: 'WEATHER_SETTINGS_ERROR' });
      }
    })
    .catch((err) => {
      dispatch({ type: 'WEATHER_SETTINGS_ERROR' }, err);
    });
  };
};

export const updateWeatherSettings = (overlayKey, inputs, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    firestore.collection('overlays').doc(overlayKey).get().then((overlay) => {
      let overlayData = overlay.data();
      overlayData.weather = overlayData.weather || {};
      if (inputs.weatherMode) {
        overlayData.weather.mode = inputs.weatherMode;
      }
      if (inputs.weatherStyle) {
        overlayData.weather.style = inputs.weatherStyle;
      }
      if (inputs.weatherAlign) {
        overlayData.weather.align = inputs.weatherAlign;
      }
      if (inputs.weatherSeconds) {
        overlayData.weather.secs = inputs.weatherSeconds;
      }
      if (inputs.weatherOpacity) {
        overlayData.weather.opacity = inputs.weatherOpacity;
      }
      if (inputs.weatherChatResponse) {
        overlayData.weather.chatResponse = inputs.weatherChatResponse;
      }
      if (inputs.chatViewer) {
        overlayData.weather.chatViewer = inputs.chatViewer;
      }
      if (inputs.weatherChatCooldown) {
        overlayData.weather.chatCooldown = inputs.weatherChatCooldown;
      }
      if (typeof inputs.chatTTSSymbol !== 'undefined') {
        overlayData.weather.chatTTSSymbol = inputs.chatTTSSymbol;
      }
      firestore.collection('overlays').doc(overlayKey).set(overlayData, { merge : true }).then(() => {
        dispatch({ type: 'WEATHER_SETTINGS_SUCCESS' });
        if (typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        dispatch({ type: 'WEATHER_SETTINGS_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'WEATHER_SETTINGS_ERROR' }, err);
    });
  };
};

export const updateCatchSettings = (overlayKey, inputs, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    firestore.collection('overlays').doc(overlayKey).get().then((overlay) => {
      let overlayData = overlay.data();
      overlayData.catches = overlayData.catches || {};
      overlayData.advanced = overlayData.advanced || {};
      if (inputs.catchIcon) {
        overlayData.catches.icon = inputs.catchIcon;
      }
      if (inputs.catchChatResponse) {
        overlayData.catches.chatResponse = inputs.catchChatResponse;
      }
      if (inputs.catchCard) {
        overlayData.catches.chatCard = inputs.catchCard;
      }
      if (inputs.catchChatCooldown) {
        overlayData.catches.chatCooldown = inputs.catchChatCooldown;
      }
      if (inputs.catchIconOffsetX) {
        overlayData.catches.iconOffsetX = inputs.catchIconOffsetX;
      }
      if (inputs.catchIconOffsetY) {
        overlayData.catches.iconOffsetY = inputs.catchIconOffsetY;
      }
      if (inputs.catchBagOffsetX) {
        overlayData.catches.bagOffsetX = inputs.catchBagOffsetX;
      }
      if (inputs.catchBagOffsetY) {
        overlayData.catches.bagOffsetY = inputs.catchBagOffsetY;
      }
      if (inputs.catchCardOffsetY) {
        overlayData.catches.cardOffsetY = inputs.catchCardOffsetY;
      }
      if (inputs.rankCardOffsetY) {
        overlayData.catches.rankOffsetY = inputs.rankCardOffsetY;
      }
      if (inputs.alertVolume) {
        overlayData.advanced.alertVolume = inputs.alertVolume;
      }
      firestore.collection('overlays').doc(overlayKey).set(overlayData, { merge : true }).then(() => {
        dispatch({ type: 'CATCH_SETTINGS_SUCCESS' });
        if (typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        dispatch({ type: 'CATCH_SETTINGS_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'CATCH_SETTINGS_ERROR' }, err);
    });
  };
};

export const updateAdvancedSettings = (overlayKey, inputs, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    firestore.collection('overlays').doc(overlayKey).get().then((overlay) => {
      let overlayData = overlay.data();
      overlayData.advanced = overlayData.advanced || {};
      if (inputs.overlayWidth) {
        overlayData.advanced.overlayWidth = inputs.overlayWidth;
      }
      if (inputs.overlayHeight) {
        overlayData.advanced.overlayHeight = inputs.overlayHeight;
      }
      firestore.collection('overlays').doc(overlayKey).set(overlayData, { merge : true }).then(() => {
        dispatch({ type: 'CATCH_SETTINGS_SUCCESS' });
        if (typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        dispatch({ type: 'CATCH_SETTINGS_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'CATCH_SETTINGS_ERROR' }, err);
    });
  };
};

export const updateGeneralSettings = (overlayKey, inputs, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    firestore.collection('overlays').doc(overlayKey).get().then((overlay) => {
      let overlayData = overlay.data();
      overlayData = overlayData || {};
      if (inputs.timezone) {
        overlayData.timezone = inputs.timezone;
      }
      firestore.collection('overlays').doc(overlayKey).set(overlayData, { merge : true }).then(() => {
        dispatch({ type: 'GENERAL_SETTINGS_SUCCESS' });
        if (typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        dispatch({ type: 'GENERAL_SETTINGS_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'GENERAL_SETTINGS_ERROR' }, err);
    });
  };
};

export const updateDiscordSettings = (overlayKey, inputs, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    firestore.collection('overlays').doc(overlayKey).get().then((overlay) => {
      let overlayData = overlay.data();
      overlayData.discord = overlayData.discord || {};
      if (typeof inputs.webhook === 'string') {
        overlayData.discord.webhook = inputs.webhook;
      }
      firestore.collection('overlays').doc(overlayKey).set(overlayData, { merge : true }).then(() => {
        dispatch({ type: 'DISCORD_SETTINGS_SUCCESS' });
        if (typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        dispatch({ type: 'DISCORD_SETTINGS_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'DISCORD_SETTINGS_ERROR' }, err);
    });
  };
};

export const refreshOverlay = (overlayKey, owner, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    firestore.collection('overlays').doc(overlayKey).set({ refresh : true }, { merge : true }).then(() => {
      dispatch({ type: 'OVERLAY_REFRESH_SUCCESS' });
      if (typeof callback === 'function') {
        callback();
      }
    }).catch(err => {
      dispatch({ type: 'OVERLAY_REFRESH_ERROR' }, err);
    });
  };
};

export const resetWeatherShown = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    fetch(host+'resetWeather?overlay='+id)
    .then(response => response.json())
    .then((jsonData) => {

    }).catch(err => {
      
    });
  };
};

export const resetCatchesShown = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    fetch(host+'resetCatches?overlay='+id)
    .then(response => response.json())
    .then((jsonData) => {

    }).catch(err => {
      
    });
  };
};

export const resetRankingsShown = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    fetch(host+'resetRankings?overlay='+id)
    .then(response => response.json())
    .then((jsonData) => {

    }).catch(err => {
      
    });
  };
};

export const resetBagShown = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    fetch(host+'resetBag?overlay='+id)
    .then(response => response.json())
    .then((jsonData) => {

    }).catch(err => {
      
    });
  };
};

export const resetRefresh = (id, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    fetch(host+'resetRefresh?overlay='+id)
    .then(response => response.json())
    .then((jsonData) => {
      callback(jsonData);
    }).catch(err => {
      console.log(err);
    });
  };
};

export const resetPrizeShown = (id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    fetch(host+'resetPrize?overlay='+id)
    .then(response => response.json())
    .then((jsonData) => {

    }).catch(err => {
      
    });
  };
};

export const chatbotConnect = (id, channel) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    firestore.collection('overlays').doc(id).set({
      chatbot : true
    }, { merge : true }).then((updated) => {
      return fetch(host+'joinChannel?channel='+channel)
      .then(response => response.json())
      .then((jsonData) => {
        dispatch({ type: 'CHATBOT_CONNECT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CHATBOT_CONNECT_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'CHATBOT_CONNECT_ERROR' }, err);
    });
  };
};

export const chatbotReconnect = (id, channel) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    firestore.collection('overlays').doc(id).set({
      chatbot : true
    }, { merge : true }).then((updated) => {
      return fetch(host+'joinChannel?channel='+channel)
      .then(response => response.json())
      .then((jsonData) => {
        dispatch({ type: 'CHATBOT_RECONNECT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CHATBOT_RECONNECT_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'CHATBOT_RECONNECT_ERROR' }, err);
    });
  };
};

export const chatbotDisconnect = (id, channel) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    firestore.collection('overlays').doc(id).set({
      chatbot : false
    }, { merge : true }).then((updated) => {
      return fetch(host+'leaveChannel?channel='+channel)
      .then(response => response.json())
      .then((jsonData) => {
        dispatch({ type: 'CHATBOT_DISCONNECT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CHATBOT_DISCONNECT_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'CHATBOT_DISCONNECT_ERROR' }, err);
    });
  };
};

export const addMod = (id, me) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    return firestore.collection('mods').doc(me).get().then((mods) => {
      let modList = (mods.data() && mods.data().mods && mods.data().mods.length > 0) ? mods.data().mods : [];
      modList.push(id);
      return firestore.collection('mods').doc(me).set({
        mods : modList
      }).then((update) => {
        dispatch({ type: 'ADD_MOD_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'ADD_MOD_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'ADD_MOD_ERROR' }, err);
    });
  };
};

export const removeMod = (id, me) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    return firestore.collection('mods').doc(me).get().then((mods) => {
      let modList = (mods.data() && mods.data().mods && mods.data().mods.length > 0) ? mods.data().mods : [];
      let updatedList = modList.filter(function(element) {
        return element !== id;
      });
      return firestore.collection('mods').doc(me).set({
        mods : updatedList
      }).then((update) => {
        dispatch({ type: 'REMOVE_MOD_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'REMOVE_MOD_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'REMOVE_MOD_ERROR' }, err);
    });
  };
};

export const submitFish = (data, me, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    data.owner = me;
    if (data.addNew !== true) {
      delete data.addNew;
    }
    if (data.clip === null) {
      delete data.clip;
    }
    return firestore.collection('catches').add({
      ...data
    }).then((added) => {
      dispatch({ type: 'ADD_CATCH_SUCCESS' });
      if (typeof callback === 'function') {
        callback({ success : true });
      }
    }).catch(err => {
      dispatch({ type: 'ADD_CATCH_ERROR' }, err);
      if (typeof callback === 'function') {
        callback({ error : err });
      }
    });
  };
};

export const updateFish = (id, data, me, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    return firestore.collection('catches').doc(id).get().then((fish) => {
      let newData = fish.data();
      newData = {
        ...newData,
        ...data
      };
      return firestore.collection('catches').doc(id).set({
        ...newData
      }).then((added) => {
        dispatch({ type: 'UPDATE_CATCH_SUCCESS' });
        if (typeof callback === 'function') {
          callback({ success : true });
        }
      }).catch(err => {
        dispatch({ type: 'UPDATE_CATCH_ERROR' }, err);
        if (typeof callback === 'function') {
          callback({ error : err });
        }
      });
    }).catch(err => {
      dispatch({ type: 'ADD_CATCH_ERROR' }, err);
      if (typeof callback === 'function') {
        callback({ error : err });
      }
    });
  };
};

export const deleteFish = (id, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    return firestore.collection('catches').doc(id).delete().then((deleted) => {
      dispatch({ type: 'DELETE_CATCH_SUCCESS' });
      if (typeof callback === 'function') {
        callback({ success : true });
      }
    }).catch(err => {
      dispatch({ type: 'DELETE_CATCH_ERROR' }, err);
      if (typeof callback === 'function') {
        callback({ error : err });
      }
    });
  };
};

export const rejectCatch = (id, uid, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    return firestore.collection('catches').doc(id).set({
      askVerify : false,
      locked : false,
      rejected : true,
      adminBy: uid,
      updatedAt : new Date()
    },{ merge: true}).then((updated) => {
      dispatch({ type: 'REJECT_CATCH_SUCCESS' });
      if (typeof callback === 'function') {
        callback({ success : true });
      }
    }).catch(err => {
      dispatch({ type: 'REJECT_CATCH_ERROR' }, err);
      if (typeof callback === 'function') {
        callback({ error : err });
      }
    });
  };
};

export const approveCatch = (id, uid, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirebase().firestore();
    return firestore.collection('catches').doc(id).set({
      verified : true,
      rejected : false,
      adminBy: uid,
      updatedAt : new Date()
    },{ merge: true}).then((updated) => {
      dispatch({ type: 'APPROVE_CATCH_SUCCESS' });
      if (typeof callback === 'function') {
        callback({ success : true });
      }
    }).catch(err => {
      dispatch({ type: 'APPROVE_CATCH_ERROR' }, err);
      if (typeof callback === 'function') {
        callback({ error : err });
      }
    });
  };
};