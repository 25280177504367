import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD4bkEgR_8CUY4MGl2Oq3miy7iVgZgtgt8",
  authDomain: "go-league.firebaseapp.com",
  databaseURL: "https://go-league-default-rtdb.firebaseio.com",
  projectId: "go-league",
  storageBucket: "go-league.appspot.com",
  messagingSenderId: "964800310948",
  appId: "1:964800310948:web:79767d1f12c907a0e80031",
  measurementId: "G-FKC4Z4D9V2"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
