export const joinWaitlist = (data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirebase().firestore();
    return firestore.collection('users').doc(firebase.auth().currentUser.uid).get().then((user) => {
      let userData = user.data();
      let update = false;
      if (userData.waitlist) {
        update = true;
      }
      firestore.collection('users').doc(firebase.auth().currentUser.uid).set({
        waitlist : data,
        joinedWaitlist : true
      }, { merge : true }).then(() => {
        if (update) {
          dispatch({ type: 'UPDATE_WAITLIST_SUCCESS' });
        } else {
          dispatch({ type: 'JOIN_WAITLIST_SUCCESS' });
        }
      }).catch(err => {
        dispatch({ type: 'JOIN_WAITLIST_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'LEAVE_WAITLIST_ERROR' }, err);
    });
  };
};

export const leaveWaitlist = (callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirebase().firestore();
    return firestore.collection('users').doc(firebase.auth().currentUser.uid).get().then((user) => {
      let data = user.data();
      delete data.waitlist;
      data.joinedWaitlist = false;
      firestore.collection('users').doc(firebase.auth().currentUser.uid).set({
        ...data
      }).then(() => {
        dispatch({ type: 'LEAVE_WAITLIST_SUCCESS' });
        if (typeof callback === 'function') {
          callback();
        }
      }).catch(err => {
        dispatch({ type: 'LEAVE_WAITLIST_ERROR' }, err);
      });
    }).catch(err => {
      dispatch({ type: 'LEAVE_WAITLIST_ERROR' }, err);
    });
  };
};