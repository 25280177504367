let host = (window.location.hostname === 'localhost') ? 'http://localhost:5001/go-league/us-central1/' : 'https://us-central1-go-league.cloudfunctions.net/';

export const getProducts = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    return firestore.collection('products')
      .where('active', '==', true)
      .get()
      .then(function (querySnapshot) {
        let promises = [];
        let products = [];
        promises.push(querySnapshot.forEach((doc) => {
          promises.push(doc.ref.collection('prices').get().then((someDoc) => {
            someDoc.docs.forEach((price) => {
              products.push({
                _id : doc.id,
                pricing: {
                  _id : price.id,
                  ...price.data()
                },
                ...doc.data()
              });
            });
          }));
        }));
        return Promise.all(promises).then(function(results) {
          return products;
        });
    });
  };
};

export const buyProduct = (id, price, uid) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();

    const docRef = firestore
    .collection('users')
    .doc(uid)
    .collection('checkout_sessions')
    .add({
      price: price,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    firestore.collection('users')
    .doc(uid)
    .collection('checkout_sessions')
     .onSnapshot((snapshot) => {
        let changeds = snapshot.docChanges();
        changeds.forEach((change) => {
          if (change.type === 'added') {
            const { error, sessionId } = change.doc.data();
            if (error) {
              alert(`An error occured: ${error.message}`);
            }
            if (sessionId) {
              const stripe = window.Stripe('pk_live_51Ir5fQBLQNYQpfx98MI617uINNcpkNpaNL1dhY7Q4AAzq4B42eZFt1V248p24RfTRh0lXPf85MDyqh5iDywFsqyl00RKsJhQU7');
              stripe.redirectToCheckout({ sessionId });
            }
          }
        });
    });

  };
};

export const manageProduct = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const functionRef = firebase.app().functions('us-central1').httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    return functionRef({ returnUrl: window.location.origin }).then((data) => {
      return data.data.url;
    });
  };
};

export const myProduct = (uid, callback) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    return firestore.collection('users').doc(uid).collection('subscriptions').where('status', 'in', ['trialing', 'active']).onSnapshot((snapshot) => {
      const doc = (snapshot.docs[0] && snapshot.docs[0].data) ? snapshot.docs[0].data() : {};
      let data = { ...doc };
      data.price = null;
      data.prices = null;
      data.product = null;
      callback(data);
    });
  };
};

export const acceptTOS = (uid, callback) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    return firestore.collection('users').doc(uid).set({
      TOS : true
    }, { merge : true }).then((update) => {
      dispatch({ type: 'TOS_ACCEPT' });
    }).catch(err => {
      dispatch({ type: 'ADD_MOD_ERROR' }, err);
    });
  };
};

export const joinLeague = (uid, state, index, timezone, overlay, callback) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    fetch(host+'joinLeague?uid='+uid+'&index='+index+'&timezone='+timezone+'&state='+state+'&overlay='+overlay)
    .then(response => response.json())
    .then((jsonData) => {

    }).catch(err => {
      
    });
  };
};

export const startTrial = (uid) => {
  return (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    console.log(uid);
    // fetch(host+'joinLeague?uid='+uid+'&state='+state+'&index='+index)
    // .then(response => response.json())
    // .then((jsonData) => {

    // }).catch(err => {
      
    // });
  };
};
