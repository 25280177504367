import React, { Component } from "react";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import autoBind from 'react-autobind';
import { objToArray } from '../../utils/Object';
import TimeAgo from 'react-timeago';
import { rejectCatch, approveCatch } from '../../actions/overlayActions';
import CatchHistory from '../catches/History';
import "react-datetime/css/react-datetime.css";
import Foot from "../layout/Foot";

class Admin extends Component {

  constructor(props){
    super(props);
    this.state = {
      toggleEdit : null,
      inputs : {
        approvedScale : null,
        clearWeight : null,
        foulPlay : null,
        showedZero : null
      }
    }
    autoBind(this);
  }

  toggleEdit(id) {
    this.setState({
      toggleEdit : id
    });
  }

  handleChange(e) {
    let inputs = this.state.inputs;
    inputs[e.target.name] = e.target.value
    this.setState({
      inputs: {
        ...inputs
      }
    });
  }

  rejectCatch(id) {
    this.props.rejectCatch(id, this.props.uid, () => {
      this.setState({
        inputs : {
          approvedScale : null,
          clearWeight : null,
          foulPlay : null,
          showedZero : null
        }
      });
    });
  }

  approveCatch(id) {
    this.props.approveCatch(id, this.props.uid, () => {
      this.setState({
        inputs : {
          approvedScale : null,
          clearWeight : null,
          foulPlay : null,
          showedZero : null
        }
      });
    });
  }

  render(){
    const { uid, stripeRole, usernames, admin, catches, species, goleague } = this.props;
    if (!uid) return <Redirect to="/" />;
    if (!admin || typeof admin.global !== 'number' || admin.global > 2) return <Redirect to="/" />;
    const isPremium = () => {
      return (stripeRole === 'premium');
    };
    
    return (
      <>
        <section className="hero hero-sm">
          <div className="container">
            <h1>Administration</h1>
            <h2>Manage GO League Catches</h2>
          </div>
          <div className="hero-water"></div>
        </section>
        <section>
          <div className="container">
          <h4 data-scrollto="catch-list">Pending Catches</h4>
          {
            (catches && objToArray(catches).length > 0 && usernames) ?
            <>
              <div className="catches-list pending-list">
                { 
                  objToArray(catches).filter(fish => typeof fish.species !== 'undefined').sort((a, b) => a.dateTime - b.dateTime).map(fish => {
                    return <div className="caught-fish" key={fish._id}>
                      <div className="username">{ usernames[fish.owner].display_name }</div>
                        <div className="fish-image">
                          <img src={ (species[fish.species]) ? species[fish.species].img : '/img/species-unknown.png'  } />
                          <div className="fish-name">
                            <span>{ (species[fish.species]) ? species[fish.species].display_name : fish.species }</span>
                          </div>
                        </div>
                        <div className="fish-weight">
                          <span>{ (fish.weight > 0) ? fish.weight+' lbs' : 'Unknown' } </span>
                          <span className="fish-length">{ (fish.length && fish.length > 0) ? parseFloat(fish.length)+' in' : 'Unknown' }</span>
                        </div>
                        <div className="fish-datetime">
                          <span><TimeAgo date={ fish.dateTime.toDate() } /></span>
                        </div>
                        {
                          (fish.askVerify) &&
                          <div className="fish-verified">
                            <span className="material-icons">verified</span><span className="fish-verified-label">Pending</span>
                          </div>
                        }
                        <div className="fish-buttons">
                          {
                            (fish.clip) &&
                            <a className="btn btn-primary btn-clip" href={ fish.clip } target="_blank"><span className="material-icons">video_library</span></a>
                          }
                            <button onClick={ () => this.toggleEdit(fish._id) } className="btn btn-primary btn-edit"><span className="material-icons">mode_edit</span></button>
                        </div>
                        {
                          (this.state.toggleEdit === fish._id) &&
                          <div className="edit-confirm-container">
                            <span className="edit-confirm">Admin Review of Pending Catch</span>
                            <div className="settings-form">
                              <div className="formline">
                                <label htmlFor="approvedScale">Does the clip show the fish being weighed on a digital scale?</label>
                                <select ref={el => this.approvedScale=el} value={this.state.inputs.approvedScale} onChange={this.handleChange} name="approvedScale">
                                  <option value="">Select...</option>
                                  <option value="no">No</option>
                                  <option value="yes">Yes</option>
                                </select>
                              </div>
                              {
                                (this.state.inputs.approvedScale === 'yes') &&
                                <div className="formline">
                                  <label htmlFor="showedZero">Did the streamer zero the scale first and show it zero'd?</label>
                                  <select ref={el => this.showedZero=el} value={this.state.inputs.showedZero} onChange={this.handleChange} name="showedZero">
                                    <option value="">Select...</option>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                  </select>
                                </div>
                              }
                              {
                                (this.state.inputs.showedZero === 'yes') &&
                                <div className="formline">
                                  <label htmlFor="clearWeight">Does the clip clearly show the fish weight?</label>
                                  <select ref={el => this.clearWeight=el} value={this.state.inputs.clearWeight} onChange={this.handleChange} name="clearWeight">
                                    <option value="">Select...</option>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                  </select>
                                </div>
                              }
                              {
                                (this.state.inputs.clearWeight === 'yes') &&
                                <div className="formline">
                                  <label htmlFor="foulPlay">Are you confident there was no foul play when this fish was weighed?</label>
                                  <select ref={el => this.foulPlay=el} value={this.state.inputs.foulPlay} onChange={this.handleChange} name="foulPlay">
                                    <option value="">Select...</option>
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                  </select>
                                </div>
                              }
                              <div className="form-buttons">
                                {
                                  (this.state.inputs.approvedScale === 'no' || this.state.inputs.clearWeight === 'no' || this.state.inputs.foulPlay === 'no' || this.state.inputs.showedZero === 'no') &&
                                  <button onClick={ () => this.rejectCatch(fish._id) } className="btn btn-primary btn-error">Reject Pending Catch</button>
                                }
                                {
                                  (this.state.inputs.approvedScale === 'yes' && this.state.inputs.clearWeight === 'yes' && this.state.inputs.foulPlay === 'yes' && this.state.inputs.showedZero === 'yes') &&
                                  <button onClick={ () => this.approveCatch(fish._id) } className="btn btn-primary">Approve Pending Catch</button>
                                }
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    })
                  }
                </div>
              </>
              :
              <p>There are no pending catches to review.</p>
            }
            <CatchHistory uid={uid} admin={admin} species={species} usernames={usernames} />
          </div>
        </section>
        <Foot />
      </>
    );
  };
}


const mapStateToProps = (state) => {
  const uid = state.firebase.auth.uid;
  const me = (state.firestore.data.users && state.firestore.data.users[uid]) ? Object.assign({}, state.firestore.data.users[uid]) : {};
  if (me.email) {
    delete me.email;
  }
  return {
    usernames : state.firestore.data.usernames,
    uid : uid,
    me : me,
    goleague : (state.firestore.data.settings && state.firestore.data.settings.goleague) ? state.firestore.data.settings.goleague : {},
    species : (state.firestore.data.settings && state.firestore.data.settings.goleague && state.firestore.data.settings.goleague.species) ? state.firestore.data.settings.goleague.species : {},
    catches : (state.firestore.data.catches) ? state.firestore.data.catches : {}
  };
};

const mapDispatchToProps = dispatch => {
  return {
    rejectCatch: (id, uid, callback) => dispatch(rejectCatch(id, uid, callback)),
    approveCatch: (id, uid, callback) => dispatch(approveCatch(id, uid, callback))
  }
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'usernames' },
    { collection: 'settings', doc : 'goleague' },
    { collection: 'catches', 
      where: [
        ['askVerify', '==', true],
        ['locked', '==', true],
        ['verified', '!=', true]
      ]
    }
  ])
)(withRouter(Admin))