import { toast } from "react-toastify";

const overlayReducer = (state = {}, action) => {
  switch (action.type) {
    case "WEATHER_SETTINGS_SUCCESS": {
      toast.success("Successfully updated settings.");
      return state;
    }
    case "WEATHER_SETTINGS_ERROR": {
      toast.error("Error Updating Settings.");
      return state;
    }
    case "CATCH_SETTINGS_SUCCESS": {
      toast.success("Successfully updated settings.");
      return state;
    }
    case "CATCH_SETTINGS_ERROR": {
      toast.error("Error Updating Settings.");
      return state;
    }
    case "GENERAL_SETTINGS_SUCCESS": {
      toast.success("Successfully updated settings.");
      return state;
    }
    case "GENERAL_SETTINGS_ERROR": {
      toast.error("Error Updating Settings.");
      return state;
    }
    case "DISCORD_SETTINGS_SUCCESS": {
      toast.success("Successfully updated settings.");
      return state;
    }
    case "DISCORD_SETTINGS_ERROR": {
      toast.error("Error Updating Settings.");
      return state;
    }
    case "ADD_CATCH_SUCCESS": {
      toast.success("Successfully Added Catch...");
      return state;
    }
    case "ADD_CATCH_ERROR": {
      toast.error("Error Adding Catch...");
      return state;
    }
    case "REJECT_CATCH_SUCCESS": {
      toast.success("Successfully Rejected Catch...");
      return state;
    }
    case "REJECT_CATCH_ERROR": {
      toast.error("Error Rejecting Catch...");
      return state;
    }
    case "APPROVE_CATCH_SUCCESS": {
      toast.success("Successfully Approved Catch...");
      return state;
    }
    case "APPROVE_CATCH_ERROR": {
      toast.error("Error Approving Catch...");
      return state;
    }
    case "DELETE_CATCH_SUCCESS": {
      toast.success("Successfully Deleted Catch...");
      return state;
    }
    case "DELETE_CATCH_ERROR": {
      toast.error("Error Deleting Catch...");
      return state;
    }
    case "UPDATE_CATCH_SUCCESS": {
      toast.success("Successfully Updated Catch...");
      return state;
    }
    case "UPDATE_CATCH_ERROR": {
      toast.error("Error Updating Catch...");
      return state;
    }
    case "CHATBOT_CONNECT_SUCCESS": {
      toast.success("Chatbot Connecting...");
      return state;
    }
    case "CHATBOT_CONNECT_ERROR": {
      toast.error("Chatbot Error");
      return state;
    }
    case "CHATBOT_DISCONNECT_SUCCESS": {
      toast.success("Chatbot Disconnecting...");
      return state;
    }
    case "CHATBOT_DISCONNECT_ERROR": {
      toast.error("Chatbot Error");
      return state;
    }
    case "CHATBOT_RECONNECT_SUCCESS": {
      toast.success("Chatbot Reconnecting...");
      return state;
    }
    case "CHATBOT_RECONNECT_ERROR": {
      toast.error("Chatbot Error");
      return state;
    }
    default:
      return state;
  }
};

export default overlayReducer;
