import React, { Component } from "react";
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from "react-redux";
import { withRouter, Redirect, Link } from "react-router-dom";
import autoBind from 'react-autobind';
import { updateZipcode, updateWeatherSettings, chatbotConnect, chatbotDisconnect, chatbotReconnect, addMod, removeMod, updateCatchSettings, updateAdvancedSettings, updateGeneralSettings, updateDiscordSettings, refreshOverlay } from '../../actions/overlayActions';
import { buyProduct, manageProduct, startTrial } from "../../actions/productActions";
import { objToArray } from '../../utils/Object';
import Foot from "../layout/Foot";

class Settings extends Component {

  constructor(props){
    super(props);
    this.state = {
      initialized : false,
      inputs : {
        weatherSeconds : 180,
        weatherOpacity : 80,
        weatherAlign : 'left',
        weatherChatResponse : 'yes',
        catchChatResponse : 'no',
        catchCard : 'no',
        chatViewer : 'no',
        weatherChatCooldown : 3,
        catchChatCooldown : 3,
        catchIconOffsetX : 70,
        catchIconOffsetY : 45,
        catchBagOffsetX : 0,
        catchBagOffsetY : 0,
        catchCardOffsetY : 80,
        rankCardOffsetY : 80,
        overlayWidth : 1920,
        overlayHeight : 1080,
        alertVolume : 1
      }
    }
    autoBind(this);
  }

  componentDidUpdate(){
    let overlay = this.props.overlay;
    let state = {
      initialized : true,
      inputs : {},
      overlay : {
        weather : {
          zipcode : {
            input : 'zipcode'
          },
          mode : {
            input : 'weatherMode'
          },
          style : {
            input : 'weatherStyle'
          },
          align : {
            input : 'weatherAlign'
          },
          secs : {
            input : 'weatherSeconds'
          },
          opacity : {
            input : 'weatherOpacity'
          },
          chatResponse : {
            input : 'weatherChatResponse'
          },
          chatViewer : {
            input : 'chatViewer'
          },
          chatCooldown : {
            input : 'weatherChatCooldown'
          },
          chatTTSSymbol : {
            input : 'chatTTSSymbol'
          }
        },
        catches : {
          chatResponse : {
            input : 'catchChatResponse'
          },
          chatCard : {
            input : 'catchCard'
          },
          chatCooldown : {
            input : 'catchChatCooldown'
          },
          icon : {
            input : 'catchIcon'
          },
          iconOffsetX : {
            input : 'catchIconOffsetX'
          },
          iconOffsetY : {
            input : 'catchIconOffsetY'
          },
          bagOffsetX : {
            input : 'catchBagOffsetX'
          },
          bagOffsetY : {
            input : 'catchBagOffsetY'
          },
          cardOffsetY : {
            input : 'catchCardOffsetY'
          },
          rankOffsetY : {
            input : 'rankCardOffsetY'
          }
        },
        advanced : {
          overlayWidth : {
            input : 'overlayWidth'
          },
          overlayHeight : {
            input : 'overlayHeight'
          },
          alertVolume : {
            input : 'alertVolume'
          }
        },
        discord : {
          webhook : {
            input : 'webhook'
          }
        }
      }
    };
    if (this.state.initialized === false) {
      for (var parentKey in state.overlay) {
        if (state.overlay.hasOwnProperty(parentKey)) {
          for (var childKey in state.overlay[parentKey]) {
            if (state.overlay[parentKey].hasOwnProperty(childKey)) {
              if (overlay && overlay[parentKey] && this.props.overlay[parentKey][childKey] && (this.state.inputs[state.overlay[parentKey][childKey].input] === undefined || this.state.inputs[state.overlay[parentKey][childKey].input] !== this.props.overlay[parentKey][childKey])) {
                state.inputs[state.overlay[parentKey][childKey].input] = overlay[parentKey][childKey];
              }
            }
          }
        }
      }
      if (this.props.overlay && this.props.overlay.timezone && (this.state.inputs.timezone === undefined || this.state.inputs.timezone !== this.props.overlay.timezone)) {
        state.inputs.timezone = this.props.overlay.timezone;
      }
      if (Object.keys(state.inputs).length > 0) {

        for (var key in this.state.inputs) {
          if (this.state.inputs.hasOwnProperty(key)) {
            if (state.inputs[key] === undefined) {
              state.inputs[key] = this.state.inputs[key];
            }
          }
        }
        this.setState(state);
      }
    }
  }

  chatbotConnect(id) {
    this.props.chatbotConnect(id, this.props.overlay.owner);
  }

  chatbotDisconnect(id) {
    this.props.chatbotDisconnect(id, this.props.overlay.owner);
  }

  chatbotReconnect(id) {
    this.props.chatbotReconnect(id, this.props.overlay.owner);
  }

  refreshOverlay(id) {
    this.props.refreshOverlay(id, this.props.overlay.owner);
  }

  addMod(id) {
    this.props.addMod(id, this.props.uid);
  }

  removeMod(id) {
    this.props.removeMod(id, this.props.uid);
  }

  buyProduct(id, price){
    this.props.buyProduct(id, price, this.props.uid);
  }

  manageProduct(){
    this.props.manageProduct().then((url) => {
      window.location.assign(url);
    });
  }
  
  updateWeather() {
    let overlayKey = (this.props.match.params.id) ? this.props.match.params.id : this.props.me.overlayKey;
    if (!isNaN(this.state.inputs.zipcode) && this.state.inputs.zipcode.length === 5 && (typeof this.props.overlay.weather === 'undefined' || this.state.inputs.zipcode !== this.props.overlay.weather.zipcode)) {
      this.props.updateZipcode(overlayKey, this.state.inputs);
    } else {
      this.props.updateWeatherSettings(overlayKey, this.state.inputs);
    }
  }
  
  updateAdvanced() {
    let overlayKey = (this.props.match.params.id) ? this.props.match.params.id : this.props.me.overlayKey;
    this.props.updateAdvancedSettings(overlayKey, this.state.inputs);
  }
  
  
  updateCatches() {
    let overlayKey = (this.props.match.params.id) ? this.props.match.params.id : this.props.me.overlayKey;
    this.props.updateCatchSettings(overlayKey, this.state.inputs);
  }
  
  updateDiscord() {
    let overlayKey = (this.props.match.params.id) ? this.props.match.params.id : this.props.me.overlayKey;
    this.props.updateDiscordSettings(overlayKey, this.state.inputs);
  }
  
  updateGeneral() {
    let overlayKey = (this.props.match.params.id) ? this.props.match.params.id : this.props.me.overlayKey;
    this.props.updateGeneralSettings(overlayKey, this.state.inputs);
  }

  handleChange(e) {
    let inputs = this.state.inputs;
    inputs[e.target.name] = e.target.value
    this.setState({
      inputs: {
        ...inputs
      }
    });
  }

  render(){
    const { uid, stripeRole, me, beta, overlay, usernames, mods, mod, modFor, settings, startTrial, freeTrial, rankings } = this.props;
    let overlayKey = (this.props.match.params.id) ? this.props.match.params.id : this.props.me.overlayKey;
    if (!uid) return <Redirect to="/" />;
    if (typeof modFor !== 'undefined' && typeof modFor.includes === 'function' && overlay && overlay.owner && !(modFor.includes(overlay.owner))) {
      return <Redirect to="/" />;
    }
    const isPremium = () => {
      return (stripeRole === 'premium');
    };
    const isLocal = () => {
      return (window.location.hostname === 'localhost');
    };
    const getSubStatus = (flag) => {
      let active = false;
      let ends = false;
      let renews = null;
      if (me.subs && me.subs.length > 0) {
        me.subs.forEach((sub) => {
          if (sub.status === 'active') {
            active = true;
            renews = sub.current_period_end;
            if (sub.cancel_at_period_end) {
              ends = true;
            }
          }
        });
      }
      if (active && ends === false) {
        if (flag) {
          return 'You currently have the GO League premium subscription. Your subscription will renew on '+renews.toDate().toDateString()+'.';
        } else {
          return true;
        }
      }
      if (active && ends === true) {
        if (flag) {
          return 'Your GO League premium subscription will end on '+renews.toDate().toDateString()+'.';
        } else {
          return true;
        }
      }
      if (freeTrial && !trialExpired()) {
        if (flag) {
          return <span>You currently have a free trial of the GO League premium subscription. <strong>Your trial ends on { settings.freetrial.ends.toDate().toDateString() }</strong>.</span>;
        } else {
          return true;
        }
      }
      return false;
    };
    const trialExpired = () => {
      if (settings && settings.freetrial) {
        if (settings.freetrial.enabled === true) {
          if (settings.freetrial.ends.toDate() > new Date()) {
            return false;
          }
        }
      }
      return true;
    };
    const getActiveSubStatus = (flag) => {
      let active = false;
      if (me.subs && me.subs.length > 0) {
        me.subs.forEach((sub) => {
          if (sub.status === 'active') {
            active = true;
          }
        });
      }
      if (active) {
        return true;
      }
      return false;
    };
    const getRank = (uid, league, flag) => {
      let arr = [];
      let rank = 0;
      let leg = [...league].sort((a, b) => b.bagWeight - a.bagWeight);
      if (leg.length === 0) {
        return false;
      }
      let offset = 0;
      leg.forEach((user, index) => {
        let ranked = Object.assign({}, user);
        if (leg[index+1] && typeof leg[index+1].bagWeight !== 'undefined' && leg[index].bagWeight !== leg[index+1].bagWeight) {
          if (offset > 0) {
            rank += offset;
            offset = 0;
          }
          rank+=1;
        } else {
          if (offset === 0) {
            rank += 1;
          }
          if (leg[index+1]) {
            offset +=1;
          }
        }
        if (flag === 1) {
          ranked.rank = rank;
        } else if (flag === 2) {
          ranked.rank = (offset > 0) ? true : false;
        } else {
          ranked.rank = rank + ((offset > 0) ? ' (T)' : '');
        }
        arr.push(ranked);
      });
      let myRank = arr.filter(user => {
        return user._id === uid
      });
      if (myRank[0] && typeof myRank[0].rank !== 'undefined') {
        return myRank[0].rank;
      } else {
        return null;
      }
    };
    const getBag = (uid, league, flag) => {
      let leg = [...league].filter(user => user._id === uid);
      if (leg.length === 0) {
        return false;
      }
      if (flag === 2) {
        return leg[0].bagWeight;
      }
      return leg[0].bag;
    };
    let leagueData = {};
    let rankData = {};
    let multis = {};
    if (settings && settings.leagues && overlay && overlay.owner) {
      let leagues = settings.leagues;
      leagues.forEach((league, li) => {
        if (league.starts.toDate() < new Date() && new Date() < league.ends.toDate()) {
          if (league.subleagues) {
            league.subleagues.forEach((subleague, si) => {
              if (overlay.multi) {
                multis = JSON.parse(JSON.stringify(overlay.multi));
                for (let prop in multis) {
                  if (multis.hasOwnProperty(prop)) {
                    if (subleague.participants && typeof subleague.participants[multis[prop]._id] !== 'undefined') {
                      if (rankings && rankings.goleague && rankings.goleague.leagues && rankings.goleague.leagues[li] && rankings.goleague.leagues[li].subleagues && rankings.goleague.leagues[li].subleagues[si]) {
                        multis[prop].rank = getRank(multis[prop]._id, rankings.goleague.leagues[li].subleagues[si].participants);
                        multis[prop].bag = getBag(multis[prop]._id, rankings.goleague.leagues[li].subleagues[si].participants);
                        multis[prop].bagWeight = getBag(multis[prop]._id, rankings.goleague.leagues[li].subleagues[si].participants, 2);
                        multis[prop].userSubleague = si;
                      }
                    }
                  }
                }
              }
              if (subleague.participants && typeof subleague.participants[overlay.owner] !== 'undefined') {
                if (rankings && rankings && rankings.leagues && rankings.leagues[li] && rankings.leagues[li].subleagues && rankings.leagues[li].subleagues[si]) {
                  rankData = Object.assign({}, rankings.leagues[li].subleagues[si]);
                  rankData.participants = rankData.participants.filter(user => subleague.participants[user._id] === subleague.participants[overlay.owner]);
                  rankData.myRank = getRank(overlay.owner, rankData.participants);
                  rankData.myBag = getBag(overlay.owner, rankings.leagues[li].subleagues[si].participants) || 0.00;
                  rankData.myBagWeight = getBag(overlay.owner, rankings.leagues[li].subleagues[si].participants, 2) || 0;
                }
                leagueData = Object.assign({}, league);
                leagueData.userLeague = li;
                leagueData.userSubleague = si;
                leagueData.pool = leagueData.pool || 0;
                leagueData.userRegion = subleague.participants[overlay.owner];
              }
            });
          }
        }
      });
    }
    return (
      <>
        <section className="hero hero-sm">
          <div className="container">
            <h1>Settings</h1>
            <h2>Update { (overlay && overlay.owner && overlay.owner !== uid && usernames && usernames[overlay.owner] && usernames[overlay.owner].display_name) ? usernames[overlay.owner].display_name+'\'s' : 'Your' } overlay settings</h2>
          </div>
          <div className="hero-water"></div>
        </section>
        <section>
          <div className="container">
            {
              (overlay.owner && overlay.active && ((overlay.owner === uid && (getSubStatus() || beta)) || overlay.owner !== uid)) ?
              <>
                {
                  (usernames && usernames[overlay.owner] && overlay.owner !== uid) &&
                  <div className="alert-msg alert-info">
                    <span className="material-icons">colorize</span>
                    <span className="alert-msg-text">You have been granted mod access to edit this page by { usernames[overlay.owner].display_name }.</span>
                  </div>
                }
                {
                  (overlay.owner === uid && beta) &&
                  <div className="alert-msg alert-success">
                    <span className="material-icons">verified_user</span>
                    <span className="alert-msg-text">You currently have unlimited premium access granted by the admins.</span>
                  </div>
                }
                {
                  (overlay.owner === uid && getActiveSubStatus() && beta !== true) &&
                  <div className="alert-msg alert-success">
                    <span className="material-icons">verified_user</span>
                    <span className="alert-msg-text">{ getSubStatus(true) } { (getActiveSubStatus()) && <a onClick={() => this.manageProduct()}>Manage Subscriptions.</a>} </span>
                  </div>
                }
                {
                  (overlay.owner === uid && !getActiveSubStatus() && freeTrial && beta !== true) &&
                  <div className="alert-msg alert-danger">
                    <span className="material-icons">warning</span>
                    <span className="alert-msg-text">{ getSubStatus(true) }</span>
                    { (freeTrial && !getActiveSubStatus()) && <a className="btn btn-primary" onClick={() => this.buyProduct('prod_JU4W0jhcDAYBGH', 'price_1Ir6NABLQNYQpfx9CjFiZUt7')}>Subscribe Now</a> }
                  </div>
                }
                <div className="alert-msg alert-danger">
                  <span className="material-icons">warning</span>
                  <span className="alert-msg-text">Remember to read and follow the rules, including the species specific sections! <Link to="/rules">League Rules</Link></span>
                </div>
                {
                  (typeof leagueData.userSubleague === 'number' && rankData && rankData.myRank) &&
                  <div className="league-info">
                    <div className="settings-form">
                      <div className="formline">
                        <h4>{ (overlay && overlay.owner && overlay.owner !== uid && usernames && usernames[overlay.owner] && usernames[overlay.owner].display_name) ? usernames[overlay.owner].display_name+'\'s' : 'Your' } League Information</h4>
                        <div className="cols">
                          <div className="col col-2">
                            <p><strong>League:</strong> { settings.leagues[leagueData.userLeague].display_name }</p>
                            <p><strong>Subeague:</strong> { settings.leagues[leagueData.userLeague].subleagues[leagueData.userSubleague].display_name }</p>
                            <p><strong>Region:</strong> { settings.regions[leagueData.userRegion].display_name }</p>
                          </div>
                          <div className="col col-2">
                            <p><strong>Rank:</strong> { rankData.myRank }</p>
                            <p><strong>Bag Weight:</strong> { rankData.myBagWeight }</p>
                            <p><strong>Fish:</strong> { rankData.myBag.length }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  (me && me.overlayKey) &&
                    <div className="overlay-key">
                      <div className="settings-form">
                        <div className="formline">
                          <h4>Add This Browser Source</h4>
                          <input type="text" readOnly name="overlayUrl" value={ ((isLocal()) ? 'http://localhost:3000/overlay/' : 'https://goleague.tv/overlay/') + overlayKey } />
                          <label htmlFor="overlayUrl">Add this URL as a browser source in your streaming tool as a 1920px by 1080px page or whatever your stream canvas resolution is set to. You may also have to scale the size down if the widget appears to large because your stream resolution is lower.</label>
                          {
                            (overlay.refresh !== true) &&
                            <div className="refresh-button">
                              <button onClick={ () => this.refreshOverlay(overlayKey) } className="btn btn-primary">Refresh Overlay</button>
                            </div>
                          }
                          {
                            (overlay.refresh === true) &&
                            <p>Refreshing overlay...</p>
                          }
                        </div>
                      </div>
                    </div>
                  }
                  <div className="chatbot">
                    <div className="settings-form">
                      <div className="formline">
                        <h4>Chatbot Settings</h4>
                        <label>Type /mod goleaguerobot in your twitch channel to mod the bot once it is connected.</label>
                        <div className="chatbot-buttons">
                          {
                            (overlay.chatbot) ? 
                              <>
                                <button onClick={ () => this.chatbotDisconnect(overlayKey) } className="btn btn-primary btn-error">Disconnect</button>
                                <button onClick={ () => this.chatbotReconnect(overlayKey) } className="btn btn-primary">Reconnect</button>
                              </>
                            :
                              <button onClick={ () => this.chatbotConnect(overlayKey) } className="btn btn-primary">Connect</button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {
                    (overlay.owner === uid) &&
                    <div className="add-mods">
                      <div className="settings-form">
                        <div className="formline">
                          <h4>Channel Moderators</h4>
                          <label>Mods will have full access to your overlay settings and be able to submit fish on your behalf. Mods do not have to pay the subscription fee to access your settings and fish log. To add a mod, have them sign in to GO League and then search for their username below.</label>
                          { (mods && mods.length > 0) && 
                            <div className="current-mods">
                              <ul>
                                {
                                  mods.map(mod => {
                                    return <li key={mod}><span>{ (usernames && usernames[mod] && usernames[mod].display_name) ? usernames[mod].display_name : mod }</span><button onClick={ () => this.removeMod(mod) } className="btn btn-error"><span className="material-icons">remove_moderator</span> <span className="btn-text">Remove Mod</span></button></li>
                                  })
                                }
                              </ul>
                            </div>
                          }
                          <div className="chatbot-buttons">
                            <input type="text" placeholder="User Search..." ref={el => this.modSearch=el} value={this.state.inputs.modSearch} onChange={this.handleChange} name="modSearch" />
                          </div>
                          <div className="mod-results">
                            {
                              (this.state.inputs.modSearch && this.state.inputs.modSearch.length > 0) &&
                              <ul>
                                {
                                  (this.state.inputs.modSearch && this.state.inputs.modSearch.length > 0 && objToArray(usernames).filter(user => user._id !== uid).filter(user => user.display_name.toLowerCase().indexOf(this.state.inputs.modSearch.toLowerCase()) > -1).filter(user => !mods.includes(user._id)).length > 0) ?
                                  objToArray(usernames).filter(user => user._id !== uid).filter(user => user.display_name.toLowerCase().indexOf(this.state.inputs.modSearch.toLowerCase()) > -1).filter(user => !mods.includes(user._id)).map(user => {
                                    return <li key={user._id}><span>{ user.display_name }</span><button onClick={ () => this.addMod(user._id) } className="btn btn-primary"><span className="material-icons">add_moderator</span> <span className="btn-text">Add Mod</span></button></li>
                                  })
                                  :
                                  <li>No Users found.</li>
                                }
                              </ul>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  <h4>General Settings</h4>
                  <div className="settings-form settings-general">
                    <div className="col col-2">
                      <div className="formline">
                        <label htmlFor="timezone">Streamer's Current Timezone: </label>
                        <select ref={el => this.timezone=el} value={this.state.inputs.timezone} onChange={this.handleChange} name="timezone">
                          <option value="">Select...</option>
                          <option value="America/Los_Angeles">Pacific Time (US &amp; Canada)</option>
                          <option value="America/Denver">Mountain Time (US &amp; Canada)</option>
                          <option value="America/Chicago">Central Time (US &amp; Canada)</option>
                          <option value="America/New_York">Eastern Time (US &amp; Canada)</option>
                        </select>
                      </div>
                    </div>
                    <div className="formline">
                      <button onClick={ this.updateGeneral } className="btn btn-primary">Save</button>
                    </div>
                  </div>
                  <h4>Discord Integration</h4>
                  <div className="settings-form settings-general">
                    <div className="col col-2">
                      <div className="formline">
                      <label htmlFor="webhook">Your Discord Webhook: </label>
                      <input type="text" placeholder="https://discord.com/api/webhooks/..." ref={el => this.webhook=el} value={this.state.inputs.webhook} onChange={this.handleChange} name="webhook" />
                      </div>
                    </div>
                    <div className="formline">
                      <button onClick={ this.updateDiscord } className="btn btn-primary">Save</button>
                    </div>
                  </div>
                  <h4>Weather Widget Settings</h4>
                  <div className="settings-form settings-weather">
                    <div className="col col-2">
                      <div className="formline">
                        <label htmlFor="zipcode">Weather Zipcode: </label>
                        <input type="text" ref={el => this.zipcode=el} value={this.state.inputs.zipcode} onChange={this.handleChange} name="zipcode" />
                      </div>
                      <div className="formline">
                        <label htmlFor="weatherStyle">Widget Style: </label>
                        <select ref={el => this.weatherStyle=el} value={this.state.inputs.weatherStyle} onChange={this.handleChange} name="weatherStyle">
                          <option value="vertCard">Vertical Card</option>
                          <option value="minimal">Minimalistic</option>
                        </select>
                      </div>
                      <div className="formline">
                        <label htmlFor="weatherMode">Animation Mode: </label>
                        <select ref={el => this.weatherMode=el} value={this.state.inputs.weatherMode} onChange={this.handleChange} name="weatherMode">
                          <option value="always">Always Visible</option>
                          <option value="anim">Animate In and Out</option>
                          <option value="chat">Chat Triggered Only</option>
                        </select>
                      </div>
                      {
                        (this.state.inputs.weatherMode === 'anim' || (overlay && overlay.weather && overlay.weather.mode === 'anim')) &&
                        <div className="formline">
                          <label htmlFor="weatherMode">Animation Speed (Seconds): </label>
                          <input type="number" min="0" max="600" step="1" ref={el => this.weatherSeconds=el} value={this.state.inputs.weatherSeconds} onChange={this.handleChange} name="weatherSeconds" />
                        </div>
                      }
                      <div className="formline">
                        <label htmlFor="weatherAlign">Horizontal Alignment: </label>
                        <select ref={el => this.weatherAlign=el} value={this.state.inputs.weatherAlign} onChange={this.handleChange} name="weatherAlign">
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                        </select>
                      </div>
                      <div className="formline">
                        <label htmlFor="weatherMode">Background Opacity (%): </label>
                        <input type="number" min="0" max="100" step="1" ref={el => this.weatherOpacity=el} value={this.state.inputs.weatherOpacity} onChange={this.handleChange} name="weatherOpacity" />
                      </div>
                    </div>
                    <div className="col col-2">
                      <div className="formline">
                        <label htmlFor="weatherChatResponse">Enable Chatbot Response: </label>
                        <select ref={el => this.weatherChatResponse=el} value={this.state.inputs.weatherChatResponse} onChange={this.handleChange} name="weatherChatResponse">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                      {
                        (this.state.inputs.weatherChatResponse === 'yes') &&
                        <>
                          <div className="formline">
                            <label htmlFor="chatViewer">Enable Viewer Weather: </label>
                            <select ref={el => this.chatViewer=el} value={this.state.inputs.chatViewer} onChange={this.handleChange} name="chatViewer">
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                          <div className="formline">
                            <label htmlFor="weatherChatCooldown">Chatbot Cooldown (Seconds): </label>
                            <input type="number" min="3" max="60" step="1" ref={el => this.weatherChatCooldown=el} value={this.state.inputs.weatherChatCooldown} onChange={this.handleChange} name="weatherChatCooldown" />
                          </div>
                          <div className="formline">
                            <label htmlFor="chatTTSSymbol">Chatbot TTS Character: </label>
                            <input type="text" maxLength="1" ref={el => this.chatTTSSymbol=el} value={this.state.inputs.chatTTSSymbol} onChange={this.handleChange} name="chatTTSSymbol" />
                          </div>
                        </>
                      }
                    </div>
                    <div className="formline">
                      <button onClick={ this.updateWeather } className="btn btn-primary">Save</button>
                    </div>
                  </div>
                  <h4>Other Overlay Settings</h4>
                  <div className="settings-form settings-weather">
                    <div className="col col-2">
                      <div className="formline">
                        <label htmlFor="catchIcon">Show Catch Count Icon: </label>
                        <select ref={el => this.catchIcon=el} value={this.state.inputs.catchIcon} onChange={this.handleChange} name="catchIcon">
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                      <div className="formline">
                        <label htmlFor="catchChatResponse">Enable Chatbot Response: </label>
                        <select ref={el => this.catchChatResponse=el} value={this.state.inputs.catchChatResponse} onChange={this.handleChange} name="catchChatResponse">
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>
                      {
                        (this.state.inputs.catchChatResponse === 'yes') &&
                        <>
                          <div className="formline">
                            <label htmlFor="catchChatCooldown">Chatbot Cooldown (Seconds): </label>
                            <input type="number" min="3" max="60" step="1" ref={el => this.catchChatCooldown=el} value={this.state.inputs.catchChatCooldown} onChange={this.handleChange} name="catchChatCooldown" />
                          </div>
                          <div className="formline">
                            <label htmlFor="catchCard">Chat Command Overlay On: </label>
                            <select ref={el => this.catchCard=el} value={this.state.inputs.catchCard} onChange={this.handleChange} name="catchCard">
                              <option value="no">No</option>
                              <option value="yes">Yes</option>
                            </select>
                          </div>
                        </>
                      }
                      <div className="formline">
                        <label htmlFor="catchBagOffsetX">Bag Right Offset: </label>
                        <input type="number" min="0" max="1800" step="1" ref={el => this.catchBagOffsetX=el} value={this.state.inputs.catchBagOffsetX} onChange={this.handleChange} name="catchBagOffsetX" />
                      </div>
                      <div className="formline">
                        <label htmlFor="alertVolume">Alert Volume (0.00 to 1.00): </label>
                        <input type="number" min="0" max="1" step="0.01" ref={el => this.alertVolume=el} value={this.state.inputs.alertVolume} onChange={this.handleChange} name="alertVolume" />
                      </div>
                    </div>
                    <div className="col col-2">
                      <div className="formline">
                        <label htmlFor="catchIconOffsetX">Icon Right Offset: </label>
                        <input type="number" min="0" max="1800" step="1" ref={el => this.catchIconOffsetX=el} value={this.state.inputs.catchIconOffsetX} onChange={this.handleChange} name="catchIconOffsetX" />
                      </div>
                      <div className="formline">
                        <label htmlFor="catchIconOffsetY">Icon Top Offset: </label>
                        <input type="number" min="0" max="850" step="1" ref={el => this.catchIconOffsetY=el} value={this.state.inputs.catchIconOffsetY} onChange={this.handleChange} name="catchIconOffsetY" />
                      </div>
                      <div className="formline">
                        <label htmlFor="catchCardOffsetY">Card Bottom Offset: </label>
                        <input type="number" min="0" max="800" step="1" ref={el => this.catchCardOffsetY=el} value={this.state.inputs.catchCardOffsetY} onChange={this.handleChange} name="catchCardOffsetY" />
                      </div>
                      <div className="formline">
                        <label htmlFor="rankCardOffsetY">Standings Bottom Offset: </label>
                        <input type="number" min="0" max="800" step="1" ref={el => this.rankCardOffsetY=el} value={this.state.inputs.rankCardOffsetY} onChange={this.handleChange} name="rankCardOffsetY" />
                      </div>
                      <div className="formline">
                        <label htmlFor="catchBagOffsetY">Bag Bottom Offset: </label>
                        <input type="number" min="0" max="850" step="1" ref={el => this.catchBagOffsetY=el} value={this.state.inputs.catchBagOffsetY} onChange={this.handleChange} name="catchBagOffsetY" />
                      </div>
                    </div>
                    <div className="formline">
                      <button onClick={ this.updateCatches } className="btn btn-primary">Save</button>
                    </div>
                  </div>
                  <h4>Advanced Settings</h4>
                  <p>For advanced users only! Default overlay width is 1920 and height is 1080.</p>
                  <div className="settings-form settings-catch">
                    <div className="col col-2">
                      <div className="formline">
                        <label htmlFor="overlayWidth">Overlay Width: </label>
                        <input type="number" min="1920" max="4000" step="1" ref={el => this.overlayWidth=el} value={this.state.inputs.overlayWidth} onChange={this.handleChange} name="overlayWidth" />
                      </div>
                    </div>
                    <div className="col col-2">
                      <div className="formline">
                        <label htmlFor="overlayHeight">Overlay Height: </label>
                        <input type="number" min="1080" max="2000" step="1" ref={el => this.overlayHeight=el} value={this.state.inputs.overlayHeight} onChange={this.handleChange} name="overlayHeight" />
                      </div>
                    </div>
                    <div className="formline">
                      <button onClick={ this.updateAdvanced } className="btn btn-primary">Save</button>
                    </div>
                  </div>
              </>
              :
              (!getSubStatus() && !beta) ?
              <>
                <div className="alert-msg alert-danger">
                  <span className="material-icons">warning</span>
                  <span className="alert-msg-text">You do not currently have premium access to use the overlay tools or compete in any leagues.</span>
                </div>
                <h4>Sign Up for GO League Premium</h4>
                <p>Utilize the GO League streaming overlays and compete in virtual fishing leagues for cash prize pools and cash bounties — all while adding exciting content and interactivity to your livestreams. Allow your viewers to trigger overlays using chat commands to checking your fish caught, league rank, and even local weather.</p>
                <span className="signup-price"><span className="signup-price-text">$7.99</span>/mo<span className="signup-cancel">Cancel anytime</span></span>
                {
                  (settings && settings.freetrial && settings.freetrial.enabled) ?
                    <div>
                      <a className="btn btn-primary" onClick={() => startTrial(uid)}>
                        Start Free Trial 
                      </a>
                      <span className="free-trial">(Free trial ends on { settings.freetrial.ends.toDate().toDateString() })</span>
                    </div>
                  :
                    <a className="btn btn-primary" onClick={() => this.buyProduct('prod_JU4W0jhcDAYBGH', 'price_1Ir6NABLQNYQpfx9CjFiZUt7')}>
                      Join Now
                    </a>
                }
              </>
              :
              (usernames && usernames[overlay.owner] && overlay.owner !== uid && overlay.active !== true) &&
              <>
                <div className="alert-msg alert-danger">
                  <span className="material-icons">warning</span>
                  <span className="alert-msg-text">{ usernames[overlay.owner].display_name } does not currently have an active subscription.</span>
                </div>
              </>
            }
          </div>
        </section>
        <Foot />
      </>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  const uid = state.firebase.auth.uid;
  const me = (state.firestore.data.users && state.firestore.data.users[uid]) ? Object.assign({}, state.firestore.data.users[uid]) : {};
  const mySubs = (state.firestore.data.mySubs) ? Object.assign({}, state.firestore.data.mySubs) : {};
  me.subs = objToArray(mySubs);
  let overlay = {};
  let overlayKey = (ownProps.match.params.id) ? ownProps.match.params.id : me.overlayKey ;
  const settings = (state.firestore.data.settings && state.firestore.data.settings.goleague) ? state.firestore.data.settings.goleague : {};
  const rankings = (state.firestore.data.rankings && state.firestore.data.rankings.goleague) ? state.firestore.data.rankings.goleague : {};
  if (state.firestore.data.overlays && state.firestore.data.overlays[overlayKey]) {
    overlay = { ...state.firestore.data.overlays[overlayKey] };
  }
  if (me.email) {
    delete me.email;
  }
  return {
    uid : uid,
    me : me,
    overlay : overlay,
    usernames : state.firestore.data.usernames,
    mods : (state.firestore.data.mods && state.firestore.data.mods[uid] && state.firestore.data.mods[uid].mods  && state.firestore.data.mods[uid].mods.length > 0) ? state.firestore.data.mods[uid].mods : [],
    settings : settings,
    rankings : rankings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateZipcode: (overlay, settings) => dispatch(updateZipcode(overlay, settings)),
    updateWeatherSettings: (overlay, settings) => dispatch(updateWeatherSettings(overlay, settings)),
    updateCatchSettings: (overlay, settings) => dispatch(updateCatchSettings(overlay, settings)),
    updateAdvancedSettings: (overlay, settings) => dispatch(updateAdvancedSettings(overlay, settings)),
    updateGeneralSettings: (overlay, settings) => dispatch(updateGeneralSettings(overlay, settings)),
    updateDiscordSettings: (overlay, settings) => dispatch(updateDiscordSettings(overlay, settings)),
    chatbotConnect: (id, channel) => dispatch(chatbotConnect(id, channel)),
    chatbotDisconnect: (id, channel) => dispatch(chatbotDisconnect(id, channel)),
    chatbotReconnect: (id, channel) => dispatch(chatbotReconnect(id, channel)),
    refreshOverlay: (id, channel) => dispatch(refreshOverlay(id, channel)),
    buyProduct: (id, price, uid) => dispatch(buyProduct(id, price, uid)),
    manageProduct: () => dispatch(manageProduct()),
    addMod: (id, me) => dispatch(addMod(id, me)),
    removeMod: (id, me) => dispatch(removeMod(id, me)),
    startTrial: (uid) => dispatch(startTrial(uid))
  }
};

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(props => [
    { collection: 'users', doc : props.uid},
    { collection: 'users', doc : props.uid,
      subcollections: [{ collection: 'subscriptions' }],
      storeAs: 'mySubs' },
    { collection: 'overlays', doc : (!props.match.params.id) ? (props.me && props.me.overlayKey) ? props.me.overlayKey  : '' : props.match.params.id },
    { collection: 'usernames' },
    { collection: 'mods', doc : props.uid },
    { collection: 'settings', doc : 'goleague' },
    { collection: 'rankings', doc : 'goleague' }
  ])
)(Settings))